import React from "react";
class Maintainence extends React.Component {
    render() {
        return (
            <section class="main-section maintenance-wrap">
                <div class="maintenance">
                    <div class="maintenance-logo">
                        <a class="maintenance-logo-figure" href="#"><img alt="" src="https://cms.jaipurflight.com/assets/images/website_logo/light_logo.svg" /></a>
                    </div>
                    <div class="maintenance-contain">
                        <div class="maintenance-figure">
                            <img src="assets/images/maintenance.png" alt="maintenance" />
                        </div>
                        <h3 class="mt-infobox-title">We Are Under Maintenance</h3>
                        <div class="mt-infobox-description">
                            <p>Sorry for the inconvenience, but performing some maintenance at the moment.</p>
                            <p>If you need to reach us, you can always contact us on <a target="_blank" href="mailto:info@jaipurflight.com">info@jaipurflight.com</a></p>
                        </div>
                        <span class="title-text">Follow Us On</span>
                        <div class="mt-social-icons">
                            <ul class="social-link">
                                <li class="twitter-icon"><a href="twitter.com/jaipur-flight"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li class="facebook-icon"><a href="https://facebook.com/jaipur-flight"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Maintainence