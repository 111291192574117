import React from 'react';
import { currencyFormat, dateFormat, showValue, minutesToDuration, timeDifference, timeFormatHhMm, getNewDateFormted } from '../../shared/commonHelper';
import * as flightHelper from '../../shared/flightHelper';
import moment from 'moment';
import {Collapse} from 'reactstrap';
class FlightSearchDetailsRightRoundTripComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sendMailPopup: '',
            sendMailAddress: null,
            sendMailValues: [],
            isShowFareDetailpopup: false,
            limit: 10,
            offeredFare: false,
            includeFare: false,
            openSegments: [],
            mobileActiveTab: 'outbound',
            vendorFareRule: null,
            fareRuleSource: "",
            inboundSelectedFlight : "",
            outboundSelectedFlight : "",
            kafilaFareRules:[],
            containerHeight:0,
            containerStyle:{}
        };
    }

    //Show and hide send email popup
    showMainPopup = () => {
        if (this.state.sendMailPopup === '')
            this.setState({ sendMailPopup: 'show' });
        else
            this.setState({ sendMailPopup: '' });
    }

    //Set state with information of flights to be send through email
    saveEmailData = (event) => {
        if (event.target.checked === true) {
            this.state.sendMailValues.push(event.target.value);
        }
        else {
            var index = this.state.sendMailValues.indexOf(event.target.value);
            if (index > -1) {
                this.state.sendMailValues.splice(index, 1);
            }
        }
    }

    //Send itineraries mails
    sendMail = () => {
        this.props.sendItinerariesMail(this.state.sendMailAddress, this.state.sendMailValues, this.state.includeFare);
    }

    //Show fare details
    
    showFareDetailsPopup = (event, resultIndex = '', isVendor = '', fareRules = '', segements = '', apiSource = '', uid = '',airline_remark='',airline_code,supplier_airline_remark,flightId,trackId ) => {
        let traceID = this.props.searchResult.trace_id;

        // if (resultIndex !== '' && isVendor !== 1  && apiSource !== "kafila") {
        //     this.props.flightFareDetails(traceID, resultIndex, segements, apiSource,uid);
        // }
        if (resultIndex !== '' && isVendor !== 1) {
            this.props.flightFareDetails(traceID, resultIndex, segements, apiSource,uid,airline_remark,airline_code,supplier_airline_remark ,flightId,trackId);
        }
        // if (apiSource === "kafila") {
        //     this.setState({
        //         kafilaFareRules: fareRules
        //     })
        // }
        
        this.setState({
            fareRuleSource: apiSource
        })
        
        if (isVendor === 1) {
            this.setState({
                vendorFareRule: fareRules
            });
        } else {
            this.setState({
                vendorFareRule: null
            });
        }

        this.setState({ isShowFareDetailpopup: this.state.isShowFareDetailpopup === true ? false : true })
    }

    trackScrolling = (event) => {
        let section = document.getElementsByClassName("page-container")[0];
        let footer = document.getElementsByTagName("footer")[0];
        let pageScorllRemaining = (section.scrollHeight + footer.scrollHeight - window.pageYOffset - window.innerHeight);
        if (pageScorllRemaining < footer.scrollHeight) {
            this.loadMoreDataM();
        }
    }
    handleResize=()=>{
        if(window.innerWidth <= 766){
            document.getElementById("cont-st").classList.remove('page-containers-start')
            document.getElementById("cont-st-outbound").classList.remove('page-containers-start-outbound')
            this.setState({containerStyle:{}})
            document.addEventListener('scroll', this.trackScrolling);
        }else{
            document.getElementById("cont-st").classList.add('page-containers-start') 
            document.getElementById("cont-st-outbound").classList.add('page-containers-start-outbound')
            document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
            document.getElementById("cont-st-outbound").addEventListener('scroll', this.trackScrollingInbound);
        }
      }
      loadMoreDataM = () => {
          let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0], this.props.resultSorting, 'outbound');
          const sameFlightOutboundResults = totalResultsOutbound && totalResultsOutbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsOutbound);
          const allSameFlightOutboundKeys = totalResultsOutbound && Object.keys(sameFlightOutboundResults);
          let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1], this.props.resultSorting, 'inbound');
          const sameFlightInboundResults = totalResultsInbound && totalResultsInbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsInbound);
          const allSameFlightInboundKeys = totalResultsInbound && Object.keys(sameFlightInboundResults);
          if(this.state.mobileActiveTab === 'outbound'){
            const nextResultsToShow = Math.min(this.props.isFilterCheckedStatus + 25, allSameFlightOutboundKeys.length);
            this.props.isFilterChecked(nextResultsToShow)
          }else{
            const nextResultsToShowInbound = Math.min(this.props.isFilterCheckedStatusInound + 25, allSameFlightInboundKeys.length);
            this.props.isFilterCheckedInbound(nextResultsToShowInbound)
          }
      };
    loadMoreData = () => {
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0],this.props.resultSorting,'outbound');
        const sameFlightOutboundResults = totalResultsOutbound && totalResultsOutbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsOutbound);
        const allSameFlightOutboundKeys = totalResultsOutbound && Object.keys(sameFlightOutboundResults);
        const nextResultsToShow = Math.min(this.props.isFilterCheckedStatus + 25, allSameFlightOutboundKeys.length);
        this.props.isFilterChecked(nextResultsToShow)
      };
      loadMoreDataInbound = () => {
        let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1],this.props.resultSorting,'inbound');
        const sameFlightInboundResults  = totalResultsInbound && totalResultsInbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsInbound);
        const allSameFlightInboundKeys  = totalResultsInbound && Object.keys(sameFlightInboundResults);
        const nextResultsToShow = Math.min(this.props.isFilterCheckedStatusInound + 25, allSameFlightInboundKeys.length);
        this.props.isFilterCheckedInbound(nextResultsToShow)
      };
    trackScrollingM = () => {
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0],this.props.resultSorting,'outbound');
        const sameFlightOutboundResults = totalResultsOutbound && totalResultsOutbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsOutbound);
        const allSameFlightOutboundKeys = totalResultsOutbound && Object.keys(sameFlightOutboundResults);
        const container = document.querySelector('.page-containers-start');
        const footer = document.querySelector('.footers-end');
        if (container && footer) {
          const containerScrollRemaining =
            container.scrollHeight - container.scrollTop - container.clientHeight;
          const footerHeight = footer.scrollHeight;
        if (containerScrollRemaining-2 < footerHeight && this.props.isFilterCheckedStatus < allSameFlightOutboundKeys.length) {
            this.loadMoreData();
          }
        }
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
          }
      
          this.scrollTimeout = setTimeout(() => {
            if (container.scrollHeight < 200) {
              this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: "" });
            } else {
              this.props.scroll_position({...this.props.track_scroll_position, childContainer: 1 });
            }
          }, 500); 
      };
      trackScrollingInbound = () => {
        let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1],this.props.resultSorting,'inbound');
        const sameFlightInboundResults  = totalResultsInbound && totalResultsInbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsInbound);
        const allSameFlightInboundKeys  = totalResultsInbound && Object.keys(sameFlightInboundResults);
        const container = document.querySelector('.page-containers-start-outbound');
        const footer = document.querySelector('.footers-end-outbound');
        if (container && footer) {
          const containerScrollRemaining =
            container.scrollHeight - container.scrollTop - container.clientHeight;
          const footerHeight = footer.scrollHeight;
        if (containerScrollRemaining-2 < footerHeight && this.props.isFilterCheckedStatusInound < allSameFlightInboundKeys.length) {
            this.loadMoreDataInbound();
          }
        }
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
          }
      
          this.scrollTimeout = setTimeout(() => {
            if (container.scrollHeight < 200) {
              this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: "" });
            } else {
              this.props.scroll_position({ ...this.props.track_scroll_position,childContainer: 1 });
            }
          }, 500); 
      };
    
    

    //For hide/show offered fare
    toggleOfferedFare = () => {
        this.setState({ offeredFare: !this.state.offeredFare });
        this.props.showBottomOfferedFare();
    }

    //For bottom flight show according to left filters
    trackClicking = (event) => {
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0]);
        let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1]);

         if (event.target.className === 'is-filter' || event.target.className === 'active is-filter' || event.target.className === 'fa fa-inr is-filter') {
            if (totalResultsOutbound && totalResultsOutbound[0]) {
                this.props.getBottomDepartureFlightsDetails([totalResultsOutbound[0]]);
                this.setState({outboundSelectedFlight : totalResultsOutbound[0]});
            }

            if (totalResultsInbound && totalResultsInbound[0]) {
                this.props.getBottomReturnFlightsDetails([totalResultsInbound[0]]);
                this.setState({inboundSelectedFlight : totalResultsInbound[0]});
            }
        }
    }

    componentDidMount() {
        let liHeight=document.getElementById('calchRoundHeight') && document.getElementById('calchRoundHeight').clientHeight
        let filterHEight=document.getElementById('adv-filter');
        let cliHeight=filterHEight && filterHEight.clientHeight;
        let resultToREnder=liHeight ? Math.ceil(cliHeight/liHeight)+3 : 0
        let isResultToREnder = resultToREnder && resultToREnder > 20  ? resultToREnder : 20
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0],this.props.resultSorting,'outbound');
        const sameFlightOutboundResults = totalResultsOutbound && totalResultsOutbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsOutbound);
        const allSameFlightOutboundKeys = totalResultsOutbound && Object.keys(sameFlightOutboundResults);
        let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1],this.props.resultSorting,'inbound');
        const sameFlightInboundResults  = totalResultsInbound && totalResultsInbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsInbound);
        const allSameFlightInboundKeys  = totalResultsInbound && Object.keys(sameFlightInboundResults);
        if((allSameFlightOutboundKeys && allSameFlightOutboundKeys.length) || (allSameFlightInboundKeys && allSameFlightInboundKeys.length)){
            for (let initialResults = 6; initialResults <= isResultToREnder; initialResults = initialResults + 3) {
                setTimeout(() => {
                    this.props.isFilterChecked(initialResults);
                    this.props.isFilterCheckedInbound(initialResults);
                }, 10 * (initialResults - 1));
            }
        }
          window.addEventListener('resize',this.handleResize)
          if(window.innerWidth <= 766){
            this.setState({containerStyle:{}})
            document.getElementById("cont-st") && document.getElementById("cont-st").classList.remove('page-containers-start')
            document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").classList.remove('page-containers-start-outbound')
            document.addEventListener('scroll', this.trackScrolling);
          }else{
              document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
              document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").classList.add('page-containers-start-outbound')
              document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
              document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").addEventListener('scroll', this.trackScrollingInbound);
          }
        
        document.addEventListener('click', this.trackClicking.bind(this));

        // Condition for default selected min price flights on bottom
        if (this.props.searchResult && this.props.searchResult.results) {
            let minInboundFlightFare, minOutboundFlightFare = 0;
            let self = this;
            // default selected min price Inbound flight on bottom
            if (this.props.searchResult && this.props.searchResult.results && this.props.searchResult.results[0]) {
                for (let i = 0; i < this.props.searchResult.results[0].length; i++) {
                    let data = this.props.searchResult.results[0][i];
                    if (i === 0) {
                        minOutboundFlightFare = data.fare.published_fare;
                        self.props.getBottomDepartureFlightsDetails([data]);
                        self.setState({outboundSelectedFlight : data});
                    }
                    if (data.fare.published_fare < minOutboundFlightFare) {
                        minOutboundFlightFare = data.fare.published_fare;
                        self.props.getBottomDepartureFlightsDetails([data]);
                        self.setState({outboundSelectedFlight : data});
                    }
                }
            }
            // default selected min price Outbound flight on bottom
            if (this.props.searchResult && this.props.searchResult.results && this.props.searchResult.results[1]) {
                for (let i = 0; i < this.props.searchResult.results[1].length; i++) {
                    let data = this.props.searchResult.results[1][i];
                    if (i === 0) {
                        minInboundFlightFare = data.fare.published_fare;
                        self.props.getBottomReturnFlightsDetails([data]);
                        self.setState({inboundSelectedFlight : data});
                    }
                    if (data.fare.published_fare < minInboundFlightFare) {
                        minInboundFlightFare = data.fare.published_fare;
                        self.props.getBottomReturnFlightsDetails([data]);
                        self.setState({inboundSelectedFlight : data});
                    }
                }
            }
        }
        else {
            let self = this;
            self.props.getBottomDepartureFlightsDetails([]);
            self.props.getBottomReturnFlightsDetails([]);
            self.setState({inboundSelectedFlight : {}, outboundSelectedFlight : {}});
        }
    }

    componentWillUnmount() {
        if(window.innerWidth <= 766){
            document.removeEventListener('scroll', this.trackScrolling);
        }else{
            document.removeEventListener('scroll', this.trackScrollingM);
            document.removeEventListener('scroll', this.trackScrollingInbound);
        }
        window.removeEventListener('resize',this.handleResize)
        document.removeEventListener('click', this.trackClicking.bind(this));
    }
    componentDidUpdate() {
        if(document.getElementsByClassName("page-containers-start") && document.getElementsByClassName("page-containers-start").length === 0){
            if (window.innerWidth <= 766) {
            } else {
                document.getElementById("cont-st") && document.getElementById("cont-st").classList.add('page-containers-start')
                document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").classList.add('page-containers-start-outbound')
                document.getElementById("cont-st") && document.getElementById("cont-st").addEventListener('scroll', this.trackScrollingM);
                document.getElementById("cont-st-outbound") && document.getElementById("cont-st-outbound").addEventListener('scroll', this.trackScrollingInbound);
            }
        }
    }

    //Prev day Outbound flight search
    prevDayOutboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[0].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() - 1);
        let prevDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[0].preferred_arrival_time = prevDay;
        this.props.searchQuery.segments[0].preferred_departure_time = prevDay;
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        setTimeout(() => {
            if (is_kafila_user === 'Y' && ['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' ||
                    this.props.searchQuery.pft === 'STUDENT')) {
                if (this.props.searchQuery.is_domestic === 'false' && (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" || this.props.searchQuery.journey_url === 'lcc-special-return')) {
                } else {
                    if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
                        this.props.getKafilaFlightSearchResults(this.props.searchQuery);
                    }
                }
            }
		}, 1000);
        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
            if(this.props.searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(this.props.searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }, 2000);    
            }
            else if(['1', '2'].includes(this.props.searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
            }
        }
        if(this.props.searchQuery.pft === 'REGULAR'  || this.props.searchQuery.pft === 'STUDENT' || this.props.searchQuery.pft === 'SENIOR_CITIZEN')
        {
		    this.props.getFlightSearchResults(this.props.searchQuery);
        }
        setTimeout(() => {
            if (
                (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR')) {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || 
                    this.props.searchQuery.flightCabinClassName === 'First' ||
                     this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if(this.props.searchQuery.is_domestic === 'false'){
                        if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        }
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                         this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (
                ['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journeyUrl !== 'advance-search') {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                    this.props.geteflyFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search') {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {

                }
                else {
                    if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                    this.props.getAirIQFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (
                (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR')) {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if(this.props.searchQuery.is_domestic === 'false'){
                        if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                        }
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                         this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3200);
        setTimeout(() => {
            if(['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' || this.props.searchQuery.pft === 'STUDENT'))
            {
                if(this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search'){
                    this.props.getRiyaFlightSearchResults(this.props.searchQuery);        
                }
            }
        }, 1000); 
      

        setTimeout(() => {
            if(['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR'))
            {
                if(this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search'){
                    if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                        this.props.getFareBoutiqueFlightSearchResults(this.props.searchQuery);
                    }
                    
                }
            }
        }, 1000);
    }

    //Next day Inbound flight search
    nextDayOutboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[0].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() + 1);
        let nextDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[0].preferred_arrival_time = nextDay;
        this.props.searchQuery.segments[0].preferred_departure_time = nextDay;
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        setTimeout(() => {
            if (is_kafila_user === 'Y' && ['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' ||
                    this.props.searchQuery.pft === 'STUDENT')) {
                if (this.props.searchQuery.is_domestic === 'false' && (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" || this.props.searchQuery.journey_url === 'lcc-special-return')) {
                } else {
                    if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
                        this.props.getKafilaFlightSearchResults(this.props.searchQuery);
                    }
                }
            }
		}, 1000);
        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
            if(this.props.searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(this.props.searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }, 2000);    
            }
            else if(['1', '2'].includes(this.props.searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
            }
        }
        if(this.props.searchQuery.pft === 'REGULAR'  || this.props.searchQuery.pft === 'STUDENT' || this.props.searchQuery.pft === 'SENIOR_CITIZEN')
        {
		    this.props.getFlightSearchResults(this.props.searchQuery);
        }
        setTimeout(() => {
            if (
                (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR')) {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || 
                    this.props.searchQuery.flightCabinClassName === 'First' ||
                     this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if(this.props.searchQuery.is_domestic === 'false'){
                        if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        }
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                         this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (
                ['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journeyUrl !== 'advance-search') {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                    this.props.geteflyFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search') {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {

                }
                else {
                    if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                    this.props.getAirIQFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (
                (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR')) {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if(this.props.searchQuery.is_domestic === 'false'){
                        if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                        }
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                         this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3200);
        setTimeout(() => {
            if(['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' || this.props.searchQuery.pft === 'STUDENT'))
            {
                if(this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search'){
                    this.props.getRiyaFlightSearchResults(this.props.searchQuery);        
                }
            }
        }, 1000); 
      

        setTimeout(() => {
            if(['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR'))
            {
                if(this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search'){
                    if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                        this.props.getFareBoutiqueFlightSearchResults(this.props.searchQuery);
                    }
                    
                }
            }
        }, 1000);
    }

    //Prev day Inbound flight search
    prevDayInboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[1].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() - 1);
        let prevDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[1].preferred_arrival_time = prevDay;
        this.props.searchQuery.segments[1].preferred_departure_time = prevDay;
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        setTimeout(() => {
            if (is_kafila_user === 'Y' && ['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' ||
                    this.props.searchQuery.pft === 'STUDENT')) {
                if (this.props.searchQuery.is_domestic === 'false' && (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" || this.props.searchQuery.journey_url === 'lcc-special-return')) {
                } else {
                    if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
                        this.props.getKafilaFlightSearchResults(this.props.searchQuery);
                    }
                }
            }
		}, 1000);
        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
            if(this.props.searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(this.props.searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }, 2000);    
            }
            else if(['1', '2'].includes(this.props.searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
            }
        }
        if(this.props.searchQuery.pft === 'REGULAR'  || this.props.searchQuery.pft === 'STUDENT' || this.props.searchQuery.pft === 'SENIOR_CITIZEN')
        {
		    this.props.getFlightSearchResults(this.props.searchQuery);
        }
        setTimeout(() => {
            if (
                (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR')) {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || 
                    this.props.searchQuery.flightCabinClassName === 'First' ||
                     this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if(this.props.searchQuery.is_domestic === 'false'){
                        if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        }
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                         this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (
                ['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journeyUrl !== 'advance-search') {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                    this.props.geteflyFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search') {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {

                }
                else {
                    if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                    this.props.getAirIQFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (
                (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR')) {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if(this.props.searchQuery.is_domestic === 'false'){
                        if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                        }
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                         this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3200);
        setTimeout(() => {
            if(['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' || this.props.searchQuery.pft === 'STUDENT'))
            {
                if(this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search'){
                    this.props.getRiyaFlightSearchResults(this.props.searchQuery);        
                }
            }
        }, 1000); 
      

        setTimeout(() => {
            if(['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR'))
            {
                if(this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search'){
                    if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                        this.props.getFareBoutiqueFlightSearchResults(this.props.searchQuery);
                    }
                    
                }
            }
        }, 1000);
    }

    //Next day Outbound flight search
    nextDayInboundFlightSearch = () => {
        let updatedDate = getNewDateFormted(this.props.searchQuery.segments[1].preferred_arrival_time.split('T')[0]);
        updatedDate.setDate(updatedDate.getDate() + 1);
        let nextDay = moment(updatedDate).format('YYYY-MM-DD') + 'T00:00:00';
        this.props.searchQuery.segments[1].preferred_arrival_time = nextDay;
        this.props.searchQuery.segments[1].preferred_departure_time = nextDay;
        const is_kafila_user = localStorage.getItem('is_kafila_user');
        setTimeout(() => {
            if (is_kafila_user === 'Y' && ['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' ||
                    this.props.searchQuery.pft === 'STUDENT')) {
                if (this.props.searchQuery.is_domestic === 'false' && (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" || this.props.searchQuery.journey_url === 'lcc-special-return')) {
                } else {
                    if (this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS") {
                        this.props.getKafilaFlightSearchResults(this.props.searchQuery);
                    }
                }
            }
		}, 1000);
        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
            if(this.props.searchQuery.pft === 'REGULAR')
            {
                setTimeout(() => {
                    if(['1', '2'].includes(this.props.searchQuery.journey_type))
                        {
                                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
                        }
                    }, 2000);    
            }
            else if(['1', '2'].includes(this.props.searchQuery.journey_type))
            {
                this.props.getTripJackFlightSearchResults(this.props.searchQuery);
            }
        }
        if(this.props.searchQuery.pft === 'REGULAR'  || this.props.searchQuery.pft === 'STUDENT' || this.props.searchQuery.pft === 'SENIOR_CITIZEN')
        {
		    this.props.getFlightSearchResults(this.props.searchQuery);
        }
        setTimeout(() => {
            if (
                (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR')) {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || 
                    this.props.searchQuery.flightCabinClassName === 'First' ||
                     this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if(this.props.searchQuery.is_domestic === 'false'){
                        if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        }
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                         this.props.getGoflyFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (
                ['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journeyUrl !== 'advance-search') {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                    this.props.geteflyFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (this.props.searchQuery.pft === 'REGULAR' && this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search') {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {

                }
                else {
                    if (this.props.searchQuery.is_domestic === 'false' &&  (this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" || this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return')) {
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                    this.props.getAirIQFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3000);

        setTimeout(() => {
            if (
                (['1', '2', '5'].includes(this.props.searchQuery.journey_type) &&
                this.props.searchQuery.pft === 'REGULAR')) {
                if (this.props.searchQuery.flightCabinClassName === 'Business' || this.props.searchQuery.flightCabinClassName === 'First' || this.props.searchQuery.flightCabinClassName === 'Premium Economy') {
                } else {
                    if(this.props.searchQuery.is_domestic === 'false'){
                        if(this.props.searchQuery.journey_url === "round-trip" || this.props.searchQuery.journey_url === "round-trip-complete" ||this.props.searchQuery.journey_url === "special-return" ||  this.props.searchQuery.journey_url === 'lcc-special-return'){
                            if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                                this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                            }
                        }else{
                            this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                        }
                    }else{
                        if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                         this.props.getSatkarFlightSearchResults(this.props.searchQuery);
                        }
                    }
                }
            }
        }, 3200);
        setTimeout(() => {
            if(['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR' || this.props.searchQuery.pft === 'SENIOR_CITIZEN' || this.props.searchQuery.pft === 'STUDENT'))
            {
                if(this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search'){
                    this.props.getRiyaFlightSearchResults(this.props.searchQuery);        
                }
            }
        }, 1000); 
      

        setTimeout(() => {
            if(['1', '2','5'].includes(this.props.searchQuery.journey_type) && (this.props.searchQuery.pft === 'REGULAR'))
            {
                if(this.props.searchQuery.journey_url !== "multi-city" && this.props.searchQuery.journey_url !== 'advance-search'){
                    if(this.props.searchQuery.roundTripType !== "LCC" && this.props.searchQuery.roundTripType !== "GDS"){
                        this.props.getFareBoutiqueFlightSearchResults(this.props.searchQuery);
                    }
                    
                }
            }
        }, 1000);
    }
    getReturnFlightsDetails= (data)=> {
        this.setState({inboundSelectedFlight : data});
    }

    getDepartureFlightsDetails = (data) => {
        this.setState({outboundSelectedFlight : data});
    }

    // set open/close segment details
    setOpenSegment = (resultIndex) => {
        let openSegments = this.state.openSegments;
        let foundIndex = false;
        let sameFound = false;

        openSegments.length > 0 && openSegments.map((flightResultIndex, index) => {
            if (flightResultIndex === resultIndex) {
                openSegments.splice(index, 1);
                sameFound = true;
            }
            else if (flightResultIndex.indexOf('OB') > -1 && resultIndex.indexOf('OB') > -1) {
                openSegments.splice(index, 1);
                foundIndex = true;
            }

            if (flightResultIndex === resultIndex) {
                openSegments.splice(index, 1);
                sameFound = true;
            }
            else if (flightResultIndex.indexOf('IB') > -1 && resultIndex.indexOf('IB') > -1) {
                openSegments.splice(index, 1);
                foundIndex = true;
            }
            return true;
        })

        if (openSegments.length < 2 || foundIndex === true) {
            if (sameFound === false) {
                openSegments.push(resultIndex);
            }
            this.setState({ openSegments: openSegments });
        }
    }

    //Set inbound or outbound active in mobile view
    setActiveBound = (value) => {
        this.setState({
            mobileActiveTab: value
        })
    }

    setSelectedCollapse = (target) =>{
        this.setState({selectedCollapse: target})
    }

    render() {
        
        let flightFareRulesData = this.state.fareRuleSource === 'tripjack' ? this.props.tripJackFlightFareRuleDetails.response :
        this.state.fareRuleSource === 'kafila' ?  this.props.kafilaFlightFareRuleDetails.response :
        this.state.fareRuleSource === 'omairiq' ?  this.props.airIQFlightFareRuleDetails.response :
        this.state.fareRuleSource === 'gofly' ?  this.props.goflyFlightFareRuleDetails.response :
        this.state.fareRuleSource === 'efly' ?  this.props.eflyFlightFareRuleDetails.response :
        this.state.fareRuleSource === 'satkar' ?  this.props.satkarFlightFareRuleDetails.response :
        this.state.fareRuleSource === 'fbq' ?  this.props.fareBoutiqueFlightFareRuleDetails.response :
        this.state.fareRuleSource === 'riya_travel' ?  this.props.riyaFlightFareRuleDetails.response : this.props.flightFareRuleDetails.response;
        let traceId = this.props.searchResult && this.props.searchResult.trace_id;
        let searchQuery = this.props.searchQuery;
        let self = this;
        let totalResultsOutbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[0], this.props.searchResult.results[0],this.props.resultSorting,'outbound');
        let totalResultsInbound = this.props.searchResult && this.props.searchResult.results && flightHelper.filterResults(this.props.advanceSearchFilter[1], this.props.searchResult.results[1],this.props.resultSorting,'inbound');
        let { getBottomDepartureFlightsDetails, getBottomReturnFlightsDetails } = this.props;
        let { offeredFare } = this.state;
        let minOutboundFlightFare, minInboundFlightFare = 0;

        const sameFlightOutboundResults = totalResultsOutbound && totalResultsOutbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsOutbound);
        const allSameFlightOutboundKeys = totalResultsOutbound && Object.keys(sameFlightOutboundResults);

        const sameFlightInboundResults  = totalResultsInbound && totalResultsInbound.length > 0 && flightHelper.sameFlightFilterResults(totalResultsInbound);
        const allSameFlightInboundKeys  = totalResultsInbound && Object.keys(sameFlightInboundResults);
        const {selectedCollapse}        = this.state;
        let fairRuleSeatOriginDestination = '';
        let fairRules = [];
        if(this.state.fareRuleSource === 'tripjack' && flightFareRulesData && Object.keys(flightFareRulesData).length && Object.keys(flightFareRulesData)[0] !== '0')
        {
            fairRuleSeatOriginDestination = flightFareRulesData['fareRule'] ?  Object.keys(flightFareRulesData['fareRule'])  :'';
            flightFareRulesData['fareRule'] && flightFareRulesData['fareRule'][fairRuleSeatOriginDestination] && flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr'] && Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr']).length > 0 &&
            Object.keys(flightFareRulesData['fareRule'][fairRuleSeatOriginDestination]['tfr']).forEach((fareRule, index)=>{
                if (fareRule !== "SEAT_CHARGEABLE") {
                    Object.keys(flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule]).length > 0 &&
                      Object.keys(flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule]).forEach((item, key) => {
                        let rulesArrKey=""
                        if(flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st !== undefined ||  flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== undefined){
                            rulesArrKey = (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st !== '' ?
                              flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].st + " hr - " : '') +
                              (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== '' ?
                                  (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et !== '8760' ?
                                      flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et + ' hr' :
                                      (flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].et / 24) + ' days') : '');
                        }else{
                            rulesArrKey=  flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item].pp
                        }
                           
                        if (!Array.isArray(fairRules[rulesArrKey])) {
                          fairRules[rulesArrKey] = [];
                        }
                        if (!Array.isArray(fairRules[rulesArrKey][fareRule])) {
                          fairRules[rulesArrKey][fareRule] = [];
                        }
                        fairRules[rulesArrKey][fareRule] =flightFareRulesData["fareRule"][fairRuleSeatOriginDestination]["tfr"][fareRule][item];
                      });
                  }
            })
        }

        let totalResultsOutboundStopData = flightHelper.getFlightStopsDirectFlightLength(totalResultsOutbound, searchQuery.direct_flight);
        let totalResultsInboundStopData = flightHelper.getFlightStopsDirectFlightLength(totalResultsInbound, searchQuery.direct_flight);

        return (
            <div className="right-side">
                <div className="search-right-section round-trip">
                    <div className="filter-btn"><span>Filter</span>
                        { /* eslint-disable-next-line */}
                        <a href="javascript:void(0);" className="btn btn-primary"><i className="icon icon-filter"></i> Filter</a>
                    </div>
                    {(!totalResultsOutbound || totalResultsOutbound.length === 0) && (!totalResultsInbound || totalResultsInbound.length === 0) ?
                        (
                            <div className="no-result-wrap">
                                <div className="result-wrap-outer">
                                    <div className="block">
                                        <div className="no-result-box">
                                            <figure><img src="assets/images/no-result.svg" alt="" /></figure>
                                            <h2>No results found</h2>
                                            <p>We can’t find any item matching your search.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                        <React.Fragment>
                            <div className="title-info">
                                <div className="pull-right">
                                    <div className={`btn-mail ${this.state.sendMailPopup}`}>
                                        <span className="offered-fare-btn btn btn-blue net-fare-button-round" onClick={this.toggleOfferedFare}>{this.state.offeredFare ? 'Hide Net Fare ' : 'Show Net Fare '} <i><img src="assets/images/offer-icon.svg" alt="" /></i></span>
                                        { /* eslint-disable-next-line */}
                                        <a className="btn btn-green whatsapp-button" href="javascript:void(0);" onClick={() => this.props.sendItinerariesMessage(this.state.sendMailValues)}><i className="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp </a>
                                        { /* eslint-disable-next-line */}
                                        <a className="btn btn-sky-blue" href="javascript:void(0);" onClick={this.showMainPopup}><i className="fa fa-envelope" aria-hidden="true"></i> Send Email </a>
                                        <div className="email-tooltip">
                                            <div className="tooltip-outer">
                                                <div className="form-group">
                                                    {
                                                        this.props.sendMailerror && !this.props.ItinerariesMail.loading &&
                                                        <div className="alert alert-danger"> <strong><i className="fa fa-exclamation-triangle"></i>{this.props.sendMailerror}</strong></div>
                                                    }
                                                    {
                                                        this.props.sendMailsuccess && !this.props.ItinerariesMail.loading &&
                                                        <div className="alert alert-success"> <strong> <i className="fa fa-check-circle"></i>Email sent successfully</strong></div>
                                                    }
                                                    <input className="form-control custom-filed" type="email" placeholder="Email Address" onChange={(e) => {
                                                        this.setState({ sendMailAddress: e.target.value });
                                                    }} />
                                                </div>
                                                <div className="form-checkbox">
                                                    <div className="select-check">
                                                        <small className="checkbox">
                                                            <input type="checkbox" onChange={(e) => { this.setState({ includeFare: !this.state.includeFare }); }} />
                                                            <label></label>
                                                        </small>
                                                        <span>Include Price?</span>
                                                    </div>
                                                </div>
                                                <div className="block">
                                                    <button type="submit" className={`btn btn-primary ${this.props.ItinerariesMail.loading ? 'show' : ''}`} onClick={this.sendMail}>
                                                        <div className="loader-ring"> <span></span>
                                                        </div>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-trip">
                                <div className="tabs-nav">
                                    <ul>
                                        { /* eslint-disable-next-line */}
                                        <li className={this.state.mobileActiveTab === 'outbound' ? 'active' : ''}><a onClick={() => this.setActiveBound('outbound')} href="javascript:void(0);">Outbound</a></li>
                                        { /* eslint-disable-next-line */}
                                        <li className={this.state.mobileActiveTab === 'inbound' ? 'active' : ''}><a onClick={() => this.setActiveBound('inbound')} href="javascript:void(0);">Inbound</a></li>
                                    </ul>
                                </div>
                                <div className="table-list">
                                    <div className="col-half" style={{ display: window.innerWidth < 767 ? (this.state.mobileActiveTab === 'outbound' ? 'block' : 'none') : 'block' }}>
                                        <div className="title-info">
                                            {
                                                searchQuery.direct_flight === 'true' &&
                                                <span className="no-found">Found <samp>{(!totalResultsOutboundStopData) ? 0 : totalResultsOutboundStopData.length}</samp> Flight(s)</span>
                                            }
                                            {
                                                searchQuery.direct_flight === 'false' &&
                                                <span className="no-found">Found <samp>{(!totalResultsOutbound) ? 0 : totalResultsOutbound.length}</samp> Flight(s)</span>
                                            }
                                            <div className="pull-left">
                                                <div className="city-to-city">
                                                    <small className="icon-info">
                                                        <img src="assets/images/flight-nav-grey.svg" alt="" /></small>
                                                    <span>{searchQuery.segments[0].originCityName}</span> To <span>{searchQuery.segments[0].destinationCityName}</span>
                                                </div>
                                                <div className="date-time">{dateFormat(searchQuery.segments[0].preferred_departure_time, 'DAY, DD MMM YYYY')}</div>
                                            </div>
                                            <div className="pull-right">
                                                <div className="prev-next-day">
                                                    <span onClick={this.prevDayOutboundFlightSearch}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Prev Day</span>
                                                    <span onClick={this.nextDayOutboundFlightSearch}>Next Day<i className="fa fa-angle-double-right" aria-hidden="true"></i></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="inner-table-list">
                                            <div className="table-head">
                                                <div className="table-col table-col-first"><span>airline</span></div>
                                                <div className="table-col"> 
                                                    { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);" onClick={() => this.props.sortResults("departTime")}>
                                                        <span>Depart</span>
                                                        <span className="sorting">
                                                            <i className={`fa fa-sort-asc sorting-icon asc ${(this.props.sortFilter.sortType === 'asc' && this.props.sortFilter.sortBy === 'departTime') ? 'active' : ''}`}></i>
                                                            <i className={`fa fa-sort-desc sorting-icon desc ${(this.props.sortFilter.sortType === 'desc' && this.props.sortFilter.sortBy === 'departTime') ? 'active' : ''}`}></i>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="table-col"> 
                                                { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);" onClick={() => this.props.sortResults("arriveTime")}>
                                                        <span>Arrive</span>
                                                        <span className="sorting">
                                                            <i className={`fa fa-sort-asc sorting-icon asc ${(this.props.sortFilter.sortType === 'asc' && this.props.sortFilter.sortBy === 'arriveTime') ? 'active' : ''}`}></i>
                                                            <i className={`fa fa-sort-desc sorting-icon desc ${(this.props.sortFilter.sortType === 'desc' && this.props.sortFilter.sortBy === 'arriveTime') ? 'active' : ''}`}></i>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="table-col"> 
                                                { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);" onClick={() => this.props.sortResults("duration")}>
                                                        <span>Duration</span>
                                                        <span className="sorting">
                                                            <i className={`fa fa-sort-asc sorting-icon asc ${(this.props.sortFilter.sortType === 'asc' && this.props.sortFilter.sortBy === 'duration') ? 'active' : ''}`}></i>
                                                            <i className={`fa fa-sort-desc sorting-icon desc ${(this.props.sortFilter.sortType === 'desc' && this.props.sortFilter.sortBy === 'duration') ? 'active' : ''}`}></i>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="table-col table-col-last"><span>price</span> </div>
                                            </div>
                                            <div className="table-body" id="cont-st" style={window.innerWidth <= 766 ? {} : {overflow:'auto',maxHeight:this.props.track_scroll_position && this.props.track_scroll_position.cliHeight > 1000 ? this.props.track_scroll_position.cliHeight : '1020px' ,minHeight: '1000px', }}>
                                                <ul>
                                                    {
                                                        !totalResultsOutbound || totalResultsOutbound.length === 0 ?
                                                            (
                                                                <li className="no-result-round">
                                                                    <figure><img src="assets/images/no-result.svg" alt="" /></figure>
                                                                    <div className="no-result-text">
                                                                        <h2>No results found</h2>
                                                                        <p>We can’t find any item matching your search.</p>
                                                                    </div>
                                                                </li>
                                                            ) :
                                                            (
                                                                allSameFlightOutboundKeys && allSameFlightOutboundKeys.slice(0,this.props.isFilterCheckedStatus).map(function (item, index) {
                                                                    if(sameFlightOutboundResults[item] && sameFlightOutboundResults[item].length > 1)
                                                                    {
                                                                        if (index === 0) {
                                                                            minOutboundFlightFare = sameFlightOutboundResults[item][0].fare.published_fare;
                                                                        }
                                                                        if (sameFlightOutboundResults[item][0].fare.published_fare < minOutboundFlightFare) {
                                                                            minOutboundFlightFare = sameFlightOutboundResults[item][0].fare.published_fare;
                                                                        }
                                                                        const searchQueryDestionationOnward = searchQuery && searchQuery.segments && searchQuery.segments[0].destinationAirportCode
                                                                        const dataDestinationOnward = sameFlightOutboundResults[item][0] &&
                                                                            sameFlightOutboundResults[item][0].segments &&
                                                                            sameFlightOutboundResults[item][0].segments[0] &&
                                                                            sameFlightOutboundResults[item][0].segments[0][sameFlightOutboundResults[item][0].segments[0].length - 1] &&
                                                                            sameFlightOutboundResults[item][0].segments[0][sameFlightOutboundResults[item][0].segments[0].length - 1].destination &&
                                                                            sameFlightOutboundResults[item][0].segments[0][sameFlightOutboundResults[item][0].segments[0].length - 1].destination.airport &&
                                                                            sameFlightOutboundResults[item][0].segments[0][sameFlightOutboundResults[item][0].segments[0].length - 1].destination.airport.airport_code
                                                                        return(
                                                                        <React.Fragment key={index}>
                                                                            <RoundTripFlightSearchDetails
                                                                                data={sameFlightOutboundResults[item][0]}
                                                                                key={sameFlightOutboundResults[item][0].result_index}
                                                                                searchQuery={searchQuery}
                                                                                saveEmailData={self.saveEmailData}
                                                                                showFareDetailsPopup={self.showFareDetailsPopup}
                                                                                history={self.props.history}
                                                                                flightType="departureFlightDetails"
                                                                                getBottomDepartureFlightsDetails={getBottomDepartureFlightsDetails}
                                                                                offeredFare={offeredFare}
                                                                                traceId={traceId}
                                                                                selectedFlightIndex={self.state.outboundSelectedFlight}
                                                                                setOpenSegment={self.setOpenSegment}
                                                                                getDepartureFlightsDetails = {self.getDepartureFlightsDetails}
                                                                                {...self.state}
                                                                                collapseIndex={"collapseOut"+index}
                                                                                sameFlightsLength={(sameFlightOutboundResults[item].length - 1)}
                                                                                setSelectedCollapse={self.setSelectedCollapse}
                                                                                searchQueryDestionationOnward={searchQueryDestionationOnward}
                                                                                dataDestinationOnward={dataDestinationOnward}
                                                                                index={index}
                                                                                length={totalResultsOutboundStopData && totalResultsOutboundStopData.length}
                                                                            />
                                                                            <Collapse isOpen={ ( selectedCollapse && selectedCollapse ===  "collapseOut"+index) ? true : false } timeout={{ appear: 200, enter: 300, exit: 700}}>
                                                                                {
                                                                                    /* eslint-disable-next-line */
                                                                                    sameFlightOutboundResults[item].map(function (data, key)
                                                                                    {
                                                                                        if(key > 0 && selectedCollapse ===  "collapseOut"+index)
                                                                                        {
                                                                                            if (data.fare.published_fare < minOutboundFlightFare) {
                                                                                                minOutboundFlightFare = data.fare.published_fare;
                                                                                            }
                                                                                            const searchQueryDestionationOnward = searchQuery && searchQuery.segments && searchQuery.segments[0].destinationAirportCode
                                                                                            const dataDestinationOnward = data &&
                                                                                                data.segments &&
                                                                                                data.segments[0] &&
                                                                                                data.segments[0][data.segments[0].length - 1] &&
                                                                                                data.segments[0][data.segments[0].length - 1].destination &&
                                                                                                data.segments[0][data.segments[0].length - 1].destination.airport &&
                                                                                                data.segments[0][data.segments[0].length - 1].destination.airport.airport_code
                                                                                            return (<RoundTripFlightSearchDetails
                                                                                                data={data}
                                                                                                key={data.result_index}
                                                                                                searchQuery={searchQuery}
                                                                                                saveEmailData={self.saveEmailData}
                                                                                                showFareDetailsPopup={self.showFareDetailsPopup}
                                                                                                history={self.props.history}
                                                                                                flightType="departureFlightDetails"
                                                                                                getBottomDepartureFlightsDetails={getBottomDepartureFlightsDetails}
                                                                                                offeredFare={offeredFare}
                                                                                                traceId={traceId}
                                                                                                selectedFlightIndex={self.state.outboundSelectedFlight}
                                                                                                setOpenSegment={self.setOpenSegment}
                                                                                                getDepartureFlightsDetails = {self.getDepartureFlightsDetails}
                                                                                                {...self.state}
                                                                                                searchQueryDestionationOnward={searchQueryDestionationOnward}
                                                                                                dataDestinationOnward={dataDestinationOnward}
                                                                                                index={index}
                                                                                                length={totalResultsOutboundStopData && totalResultsOutboundStopData.length}
                                                                                            />)
                                                                                        }
                                                                                    }) 
                                                                                }
                                                                            </Collapse>
                                                                        </React.Fragment>)
                                                                        
                                                                    }
                                                                    else
                                                                    {
                                                                        if (index === 0) {
                                                                            minOutboundFlightFare = sameFlightOutboundResults[item][0].fare.published_fare;
                                                                        }
                                                                        if (sameFlightOutboundResults[item][0].fare.published_fare < minOutboundFlightFare) {
                                                                            minOutboundFlightFare = sameFlightOutboundResults[item][0].fare.published_fare;
                                                                        }
                                                                        const searchQueryDestionationOnward = searchQuery && searchQuery.segments && searchQuery.segments[0].destinationAirportCode
                                                                        const dataDestinationOnward = sameFlightOutboundResults[item][0] &&
                                                                            sameFlightOutboundResults[item][0].segments &&
                                                                            sameFlightOutboundResults[item][0].segments[0] &&
                                                                            sameFlightOutboundResults[item][0].segments[0][sameFlightOutboundResults[item][0].segments[0].length - 1] &&
                                                                            sameFlightOutboundResults[item][0].segments[0][sameFlightOutboundResults[item][0].segments[0].length - 1].destination &&
                                                                            sameFlightOutboundResults[item][0].segments[0][sameFlightOutboundResults[item][0].segments[0].length - 1].destination.airport &&
                                                                            sameFlightOutboundResults[item][0].segments[0][sameFlightOutboundResults[item][0].segments[0].length - 1].destination.airport.airport_code
                                                                        return (
                                                                            <RoundTripFlightSearchDetails
                                                                                key={index}
                                                                                data={sameFlightOutboundResults[item][0]}
                                                                                searchQuery={searchQuery}
                                                                                saveEmailData={self.saveEmailData}
                                                                                showFareDetailsPopup={self.showFareDetailsPopup}
                                                                                history={self.props.history}
                                                                                flightType="departureFlightDetails"
                                                                                getBottomDepartureFlightsDetails={getBottomDepartureFlightsDetails}
                                                                                offeredFare={offeredFare}
                                                                                traceId={traceId}
                                                                                selectedFlightIndex={self.state.outboundSelectedFlight}
                                                                                setOpenSegment={self.setOpenSegment}
                                                                                getDepartureFlightsDetails = {self.getDepartureFlightsDetails}
                                                                                {...self.state}
                                                                                searchQueryDestionationOnward={searchQueryDestionationOnward}
                                                                                dataDestinationOnward={dataDestinationOnward}
                                                                                index={index}
                                                                                length={totalResultsOutboundStopData && totalResultsOutboundStopData.length}
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                    }
                                                </ul>
                                                <div className='footers-end'></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-half" style={{ display: window.innerWidth < 767 ? (this.state.mobileActiveTab === 'inbound' ? 'block' : 'none') : 'block' }}>
                                        <div className="title-info">
                                            {
                                                searchQuery.direct_flight === 'true' &&
                                                <span className="no-found">Found <samp>{(!totalResultsInboundStopData) ? 0 : totalResultsInboundStopData.length}</samp> Flight(s)</span>
                                            }
                                            {
                                                searchQuery.direct_flight === 'false' &&
                                                <span className="no-found">Found <samp>{(!totalResultsInbound) ? 0 : totalResultsInbound.length}</samp> Flight(s)</span>
                                            }
                                            <div className="pull-left">
                                                <div className="city-to-city">
                                                    <small className="icon-info"><img src="assets/images/flight-nav-grey.svg" alt="" /></small>
                                                    <span>{searchQuery.segments[1].originCityName}</span> To <span>{searchQuery.segments[1].destinationCityName}</span></div>
                                                <div className="date-time">{dateFormat(searchQuery.segments[1].preferred_departure_time, 'DAY, DD MMM YYYY')}</div>
                                            </div>
                                            <div className="pull-right">
                                                <div className="prev-next-day">
                                                    <span onClick={this.prevDayInboundFlightSearch}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Prev Day</span>
                                                    <span onClick={this.nextDayInboundFlightSearch}>Next Day<i className="fa fa-angle-double-right" aria-hidden="true"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="inner-table-list">
                                            <div className="table-head">
                                                <div className="table-col table-col-first"><span>airline</span></div>
                                                <div className="table-col"> 
                                                    { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);" onClick={() => this.props.sortResults("inboundDepartTime")}>
                                                        <span>Depart</span>
                                                        <span className="sorting">
                                                            <i className={`fa fa-sort-asc sorting-icon asc ${(this.props.sortFilter.inboundSortType === 'asc' && this.props.sortFilter.inboundSortBy === 'inboundDepartTime') ? 'active' : ''}`}></i>
                                                            <i className={`fa fa-sort-desc sorting-icon desc ${(this.props.sortFilter.inboundSortType === 'desc' && this.props.sortFilter.inboundSortBy === 'inboundDepartTime') ? 'active' : ''}`}></i>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="table-col"> 
                                                { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);" onClick={() => this.props.sortResults("inboundArriveTime")}>
                                                        <span>Arrive</span>
                                                        <span className="sorting">
                                                            <i className={`fa fa-sort-asc sorting-icon asc ${(this.props.sortFilter.inboundSortType === 'asc' && this.props.sortFilter.inboundSortBy === 'inboundArriveTime') ? 'active' : ''}`}></i>
                                                            <i className={`fa fa-sort-desc sorting-icon desc ${(this.props.sortFilter.inboundSortType === 'desc' && this.props.sortFilter.inboundSortBy === 'inboundArriveTime') ? 'active' : ''}`}></i>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="table-col"> 
                                                { /* eslint-disable-next-line */}
                                                    <a href="javascript:void(0);" onClick={() => this.props.sortResults("inboundDuration")}>
                                                        <span>Duration</span>
                                                        <span className="sorting">
                                                            <i className={`fa fa-sort-asc sorting-icon asc ${(this.props.sortFilter.inboundSortType === 'asc' && this.props.sortFilter.inboundSortBy === 'inboundDuration') ? 'active' : ''}`}></i>
                                                            <i className={`fa fa-sort-desc sorting-icon desc ${(this.props.sortFilter.inboundSortType === 'desc' && this.props.sortFilter.inboundSortBy === 'inboundDuration') ? 'active' : ''}`}></i>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="table-col table-col-last"><span>price</span> </div>
                                            </div>
                                            <div className="table-body" id="cont-st-outbound" style={window.innerWidth <= 766 ? {} : {overflow:'auto',maxHeight:this.props.track_scroll_position && this.props.track_scroll_position.cliHeight > 1000 ? this.props.track_scroll_position.cliHeight : "1020px" ,minHeight: '1000px' }}>
                                                <ul>
                                                    {
                                                        !totalResultsInbound || totalResultsInbound.length === 0 ?
                                                            (
                                                                <li className="no-result-round">
                                                                    <figure><img src="assets/images/no-result.svg" alt="" /></figure>
                                                                    <div className="no-result-text">
                                                                        <h2>No results found</h2>
                                                                        <p>We can’t find any item matching your search.</p>
                                                                    </div>
                                                                </li>
                                                            ) :
                                                            (
                                                                allSameFlightInboundKeys && allSameFlightInboundKeys.slice(0,this.props.isFilterCheckedStatusInound).map(function (item, index) {
                                                                    if(sameFlightInboundResults[item] && sameFlightInboundResults[item].length > 1)
                                                                    {
                                                                        if (index === 0) {
                                                                            minInboundFlightFare = sameFlightInboundResults[item][0].fare.published_fare;
                                                                        }
                                                                        if (sameFlightInboundResults[item][0].fare.published_fare < minInboundFlightFare) {
                                                                            minInboundFlightFare = sameFlightInboundResults[item][0].fare.published_fare;
                                                                        }
                                                                        const searchQueryDestionationReturn = searchQuery && searchQuery.segments && searchQuery.segments[0].destinationAirportCode
                                                                        const dataDestinationReturn = sameFlightInboundResults[item][0] &&
                                                                            sameFlightInboundResults[item][0].segments &&
                                                                            sameFlightInboundResults[item][0].segments[0] &&
                                                                            sameFlightInboundResults[item][0].segments[0][0] &&
                                                                            sameFlightInboundResults[item][0].segments[0][0].origin &&
                                                                            sameFlightInboundResults[item][0].segments[0][0].origin.airport &&
                                                                            sameFlightInboundResults[item][0].segments[0][0].origin.airport.airport_code
                                                                     
                                                                        return(
                                                                        <React.Fragment key={index}>
                                                                            <RoundTripFlightSearchDetails
                                                                                data={sameFlightInboundResults[item][0]}
                                                                                key={sameFlightInboundResults[item][0].result_index}
                                                                                searchQuery={searchQuery}
                                                                                saveEmailData={self.saveEmailData}
                                                                                showFareDetailsPopup={self.showFareDetailsPopup}
                                                                                history={self.props.history}
                                                                                flightType="returnFlightDetails"
                                                                                getBottomReturnFlightsDetails={getBottomReturnFlightsDetails}
                                                                                offeredFare={offeredFare}
                                                                                traceId={traceId}
                                                                                selectedFlightIndex={self.state.inboundSelectedFlight}
                                                                                setOpenSegment={self.setOpenSegment}
                                                                                getReturnFlightsDetails = {self.getReturnFlightsDetails}
                                                                                {...self.state}
                                                                                collapseIndex={"collapseIn"+index}
                                                                                sameFlightsLength={(sameFlightInboundResults[item].length - 1)}
                                                                                setSelectedCollapse={self.setSelectedCollapse}
                                                                                searchQueryDestionationReturn={searchQueryDestionationReturn}
                                                                                dataDestinationReturn={dataDestinationReturn}
                                                                                index={index}
                                                                                length={totalResultsInboundStopData && totalResultsInboundStopData.length}
                                                                            />
                                                                            <Collapse isOpen={ ( selectedCollapse && selectedCollapse ===  "collapseIn"+index) ? true : false } key={"collapseIn"+index}>
                                                                                {
                                                                                    /* eslint-disable-next-line */
                                                                                    sameFlightInboundResults[item].map(function (data, key)
                                                                                    {
                                                                                        if(key > 0 && selectedCollapse ===  "collapseIn"+index)
                                                                                        {
                                                                                            if (data.fare.published_fare < minInboundFlightFare) {
                                                                                                minInboundFlightFare = data.fare.published_fare;
                                                                                            }
                                                                                            const searchQueryDestionationReturn = searchQuery && searchQuery.segments && searchQuery.segments[0].destinationAirportCode
                                                                                        const dataDestinationReturn = data &&
                                                                                            data.segments &&
                                                                                            data.segments[0] &&
                                                                                            data.segments[0][0] &&
                                                                                            data.segments[0][0].origin &&
                                                                                            data.segments[0][0].origin.airport &&
                                                                                            data.segments[0][0].origin.airport.airport_code
                                                                                            return (<RoundTripFlightSearchDetails
                                                                                                data={data}
                                                                                                key={data.result_index}
                                                                                                searchQuery={searchQuery}
                                                                                                saveEmailData={self.saveEmailData}
                                                                                                showFareDetailsPopup={self.showFareDetailsPopup}
                                                                                                history={self.props.history}
                                                                                                flightType="returnFlightDetails"
                                                                                                getBottomReturnFlightsDetails={getBottomReturnFlightsDetails}
                                                                                                offeredFare={offeredFare}
                                                                                                traceId={traceId}
                                                                                                selectedFlightIndex={self.state.inboundSelectedFlight}
                                                                                                setOpenSegment={self.setOpenSegment}
                                                                                                getReturnFlightsDetails = {self.getReturnFlightsDetails}
                                                                                                {...self.state}
                                                                                                searchQueryDestionationReturn={searchQueryDestionationReturn}
                                                                                                dataDestinationReturn={dataDestinationReturn}
                                                                                                index={index}
                                                                                                length={totalResultsInboundStopData && totalResultsInboundStopData.length}
                                                                                            />)
                                                                                        }
                                                                                    }) 
                                                                                }
                                                                            </Collapse>
                                                                        </React.Fragment>)
                                                                        
                                                                    }
                                                                    else
                                                                    {
                                                                        if (index === 0) {
                                                                            minInboundFlightFare = sameFlightInboundResults[item][0].fare.published_fare;
                                                                        }
                                                                        if (sameFlightInboundResults[item][0].fare.published_fare < minInboundFlightFare) {
                                                                            minInboundFlightFare = sameFlightInboundResults[item][0].fare.published_fare;
                                                                        }
                                                                        const searchQueryDestionationReturn = searchQuery && searchQuery.segments && searchQuery.segments[0].destinationAirportCode
                                                                        const dataDestinationReturn = sameFlightInboundResults[item][0] &&
                                                                            sameFlightInboundResults[item][0].segments &&
                                                                            sameFlightInboundResults[item][0].segments[0] &&
                                                                            sameFlightInboundResults[item][0].segments[0][0] &&
                                                                            sameFlightInboundResults[item][0].segments[0][0].origin &&
                                                                            sameFlightInboundResults[item][0].segments[0][0].origin.airport &&
                                                                            sameFlightInboundResults[item][0].segments[0][0].origin.airport.airport_code
                                                                        return (
                                                                            <RoundTripFlightSearchDetails
                                                                                key={sameFlightInboundResults[item][0].result_index}
                                                                                data={sameFlightInboundResults[item][0]}
                                                                                searchQuery={searchQuery}
                                                                                saveEmailData={self.saveEmailData}
                                                                                showFareDetailsPopup={self.showFareDetailsPopup}
                                                                                history={self.props.history}
                                                                                flightType="returnFlightDetails"
                                                                                getBottomReturnFlightsDetails={getBottomReturnFlightsDetails}
                                                                                offeredFare={offeredFare}
                                                                                traceId={traceId}
                                                                                selectedFlightIndex={self.state.inboundSelectedFlight}
                                                                                setOpenSegment={self.setOpenSegment}
                                                                                getReturnFlightsDetails = {self.getReturnFlightsDetails}
                                                                                {...self.state}
                                                                                searchQueryDestionationReturn={searchQueryDestionationReturn}
                                                                                dataDestinationReturn={dataDestinationReturn}
                                                                                index={index}
                                                                                length={totalResultsInboundStopData && totalResultsInboundStopData.length}
                                                                            />
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                    }
                                                </ul>
                                                <div className='footers-end-outbound'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
                {/*Fare Detail Popup Start*/}
                <div className={this.state.isShowFareDetailpopup === true ? "popup-crad show" : "popup-crad hide"}>
                    <div className="popup-outer">
                        <div className="popup-content">
                            <div className="popup-head">
                                <h2>Fare Rules</h2>
                                <div className="popup-close"><span className="icon icon-cross" onClick={this.showFareDetailsPopup}></span></div>
                            </div>
                            <div className="popup-scorll">
                                {
                                    this.state.vendorFareRule
                                        ?
                                        this.state.vendorFareRule.map(function (data, index) {
                                            if (data.fare_rule_detail) {
                                                let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                                return (
                                                    <React.Fragment key={index}>
                                                        {
                                                            data.origin && data.destination &&
                                                            <h3>{data.origin} - {data.destination}</h3>
                                                        }
                                                        <br />
                                                        <div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} />
                                                    </React.Fragment>
                                                )
                                            }
                                            else {
                                                return (
                                                    <p key={index}><br />Fare Rules not available.<br /></p>
                                                )
                                            }
                                        })
                                        :
                                        this.state.fareRuleSource === 'tripjack' && flightFareRulesData && flightFareRulesData && Object.keys(flightFareRulesData)[0] !== '0' ? 
                                        
                                        Object.keys(fairRules).length > 0 ? <React.Fragment>
                                           <h3 className='clear_both'>{fairRuleSeatOriginDestination}</h3>
                                           <div className="table-outer">
                                                <div className="table-container content-block-cms">
                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                        <thead>
                                                            <tr>
                                                                <th width="15%">Time Frame</th>
                                                                <th style={{minWidth:'100px'}}>Cancellation Fee</th>
                                                                <th>Date Changes Fee</th>
                                                                <th>No Show</th>
                                                                {/* <th width="16%">Seat Chargeable</th> */}
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                                                                                                                        
                                                            Object.keys(fairRules).length > 0 && Object.keys(fairRules).map((fareRule, index)=>{
                                                            let fairRuleCancellationFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['policyInfo'] ? fairRules[fareRule]['CANCELLATION']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                            let fairRuleNoShow = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['policyInfo'] ? fairRules[fareRule]['NO_SHOW']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                            let fairRuleDateChange = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['policyInfo'] ? fairRules[fareRule]['DATECHANGE']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                            let fairRuleSeatChargeable = fairRules[fareRule]['SEAT_CHARGEABLE'] && fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'] ? fairRules[fareRule]['SEAT_CHARGEABLE']['policyInfo'].replaceAll('__nls__', '<br>') : '';
                                                            let fairRuleCancellationFeeAmount = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['CANCELLATION']['amount'] : '';
                                                            let fairRuleCancellationFeeAdFee = fairRules[fareRule]['CANCELLATION'] && fairRules[fareRule]['CANCELLATION']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['CANCELLATION']['additionalFee'] : '';
                                                            let fairRuleDateChangeAmount = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['amount'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['DATECHANGE']['amount'] : '';
                                                            let fairRuleDateChangeAdFee = fairRules[fareRule]['DATECHANGE'] && fairRules[fareRule]['DATECHANGE']['additionalFee'] ? '<i class="fa fa-inr" aria-hidden="true"></i>'+fairRules[fareRule]['DATECHANGE']['additionalFee'] : '';
                                                            let fairRuleNoShowDateSt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['st'] ? fairRules[fareRule]['NO_SHOW']['st'].replaceAll('__nls__', '<br>') + ' hr' + ' - ' : '';
                                                            let fairRuleNoShowDateEt = fairRules[fareRule]['NO_SHOW'] && fairRules[fareRule]['NO_SHOW']['et'] ? (fairRules[fareRule]['NO_SHOW']['et'].replaceAll('__nls__', '<br>')/24) + ' days': '';
                                                            fairRuleCancellationFee  = (fairRuleCancellationFee === 'Not Applicable') || (fairRuleCancellationFee === 'CANCELLATION Not Available') || (fairRuleCancellationFee === "Not Refundable") ? fairRuleCancellationFee : fairRuleCancellationFeeAmount+fairRuleCancellationFeeAdFee && "  "+fairRuleCancellationFeeAmount +" "+  fairRuleCancellationFee;
                                                            fairRuleDateChange = fairRuleDateChange === 'Not Applicable' || (fairRuleDateChange === "Not Refundable") ? fairRuleDateChange :  fairRuleDateChangeAmount+fairRuleDateChangeAdFee && "  "+fairRuleDateChangeAmount +" "+ fairRuleDateChange;  
                                                            
                                                            return (<tr key= {index}>
                                                                <td><div dangerouslySetInnerHTML={{__html: fareRule.replaceAll("_"," ")}}/></td>
                                                                <td><div dangerouslySetInnerHTML={{ __html: fairRuleCancellationFee}}/> </td>
                                                                <td><div  dangerouslySetInnerHTML={{ __html: fairRuleDateChange}}/> </td>
                                                                <td><div  dangerouslySetInnerHTML={{ __html: fairRuleNoShow }}/></td>
                                                                {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                                            </tr>)
                                                             })
                                                             }
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                        <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                                        <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                                        <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                                        <p>For more information Please contact our helpdesk.<br /></p>
                                                </div>
                                            </div>
                                            
                                        </React.Fragment> :  <p>
                                                    <br />Fare Rules not available.<br />
                                                    <p><br />The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                                    <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                                    <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                                    <p>For more information Please contact our helpdesk.<br /></p>
                                                </p>
                                    //     : this.state.fareRuleSource === 'kafila' && this.state.kafilaFareRules ? <React.Fragment> 
                                    //     {
                                    //         this.state.kafilaFareRules && this.state.kafilaFareRules.length>0 ? <React.Fragment>
                                    //         <h3>{this.props.searchQuery && this.props.searchQuery.originAirportCode}-{this.props.searchQuery && this.props.searchQuery.destinationAirportCode} </h3>
                                    //         <div className="table-outer">
                                    //              <div className="table-container">
                                    //                  <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    //                      <thead>
                                    //                          <tr>
                                    //                              <th width="15%">Time Frame</th>
                                    //                              <th>Cancellation Fee</th>
                                    //                              <th>Date Changes Fee</th>
                                    //                             { /*<th>No Show</th>*/}
                                    //                              {/* <th width="16%">Seat Chargeable</th> */}
                                                                 
                                    //                          </tr>
                                    //                      </thead>
                                    //                      <tbody>
                                    //                          {
                                    //                             this.state.kafilaFareRules && this.state.kafilaFareRules.length>0&&  this.state.kafilaFareRules.map((item)=>{
                                    //                                     return  (<tr key= {""}>
                                    //                                     <td>{item.time_frame}</td>
                                    //                                     <td>{ item.cancellation_fee} </td>
                                    //                                     <td>{  item.date_change_fee}</td>
                                    //                                     {/*<td><div  dangerouslySetInnerHTML={{ __html: "fairRuleNoShow" }}/></td>*/}
                                    //                                     {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                    //                                 </tr>)
                                    //                             })                                                                                                          
                                    //                           }
                                    //                      </tbody>
                                    //                  </table>
                                    //                  <br />
                                    //                      <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                    //                      <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                    //                      <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                    //                      <p>For more information Please contact our helpdesk.<br /></p>
                                    //              </div>
                                    //          </div>
                                    //         </React.Fragment>:<React.Fragment>No Fare Rules Are available</React.Fragment>
                                    //     }
                                       
                                    //  </React.Fragment>:
                                    :  this.state.fareRuleSource === 'tripjack' && flightFareRulesData && 
                                    Object.keys(flightFareRulesData) && flightFareRulesData && Object.keys(flightFareRulesData).length && flightFareRulesData && Object.keys(flightFareRulesData)[0] === '0' ? 
                                    flightFareRulesData.map(function (data, index) {
                                        let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                        return (
                                            <React.Fragment key={index}>
                                                <h3 className='clear_both'>{data.origin} - {data.destination}</h3>
                                                <div className='content-block-cms'>
                                                {fareRulesDetails ? (
                                                                <div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} />
                                                            ) : (
                                                                <div>Fare Rules Are Not Available</div>
                                                            )}
                                                </div>
                                            </React.Fragment>
                                        )
                                     })
                                    //   : this.state.fareRuleSource === 'kafila' && this.state.kafilaFareRules  ?
                                    //     <React.Fragment>
                                    //         {
                                    //             this.state.kafilaFareRules && this.state.kafilaFareRules.length > 0 ?
                                    //                 <React.Fragment>
                                    //                     <h3>{this.props.searchQuery && this.props.searchQuery.originAirportCode}-{this.props.searchQuery && this.props.searchQuery.destinationAirportCode} </h3>
                                    //                     <div className="table-outer">
                                    //                         <div className="table-container">
                                    //                             <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                    //                                 <thead>
                                    //                                     <tr>
                                    //                                         <th width="15%">Time Frame</th>
                                    //                                         <th>Cancellation Fee</th>
                                    //                                         <th>Date Changes Fee</th>
                                    //                                         { /*<th>No Show</th>*/}
                                    //                                         {/* <th width="16%">Seat Chargeable</th> */}

                                    //                                     </tr>
                                    //                                 </thead>
                                    //                                 <tbody>
                                    //                                     {
                                    //                                         this.state.kafilaFareRules && this.state.kafilaFareRules.length > 0 && this.state.kafilaFareRules.map((item) => {
                                    //                                             return (<tr key={""}>
                                    //                                                 <td>{item.time_frame}</td>
                                    //                                                 <td>{item.cancellation_fee} </td>
                                    //                                                 <td>{item.date_change_fee}</td>
                                    //                                                 {/*<td><div  dangerouslySetInnerHTML={{ __html: "fairRuleNoShow" }}/></td>*/}
                                    //                                                 {/* <td><div  dangerouslySetInnerHTML={{ __html: fairRuleSeatChargeable }}/></td> */}
                                    //                                             </tr>)
                                    //                                         })
                                    //                                     }
                                    //                                 </tbody>
                                    //                             </table>
                                    //                             <br />
                                    //                             <p>The airline charges is indicative, which will depend upon the time of cancellation / re-issue as per the airline fare rules.<br /></p>
                                    //                             <p>Mentioned Charges are Per Pax Per Sector<br /></p>
                                    //                             <p>Apart from airline charges, GST + RAF + applicable charges if any, will be charged.<br /></p>
                                    //                             <p>For more information Please contact our helpdesk.<br /></p>
                                    //                         </div>
                                    //                     </div>
                                    //                 </React.Fragment> :
                                    //                 <React.Fragment>No Fare Rules Are available</React.Fragment>
                                    //         }
                                    //     </React.Fragment> :
                                    :
                                        ((!this.props.flightFareRuleDetails.response && this.props.flightFareRuleDetails.loading === true) ||
                                         (!this.props.tripJackFlightFareRuleDetails.response && this.props.tripJackFlightFareRuleDetails.loading === true) ||
                                          (!this.props.kafilaFlightFareRuleDetails.response && this.props.kafilaFlightFareRuleDetails.loading === true) ||
                                           (!this.props.airIQFlightFareRuleDetails.response && this.props.airIQFlightFareRuleDetails.loading === true) ||
                                           (!this.props.goflyFlightFareRuleDetails.response && this.props.goflyFlightFareRuleDetails.loading === true) ||
                                           (!this.props.eflyFlightFareRuleDetails.response && this.props.eflyFlightFareRuleDetails.loading === true)||
                                           (!this.props.satkarFlightFareRuleDetails.response && this.props.satkarFlightFareRuleDetails.loading === true) ||
                                           (!this.props.fareBoutiqueFlightFareRuleDetails.response && this.props.fareBoutiqueFlightFareRuleDetails.loading === true) ||
                                           (!this.props.riyaFlightFareRuleDetails.response && this.props.riyaFlightFareRuleDetails.loading === true)) ? 
                                            (<div className="loader relative-loader" style={{ display: 'block' }}>
                                                <div className="loader-content">
                                                    <div className="loader-ring blue-ring"> <span></span> </div>
                                                </div>
                                            </div>)
                                            :
                                            flightFareRulesData && flightFareRulesData.length > 0 && flightFareRulesData.map(function (data, index) {
                                                let fareRulesDetails = data.fare_rule_detail.replace(/\r\n\t/g, '');
                                                return (
                                                    <React.Fragment key={index}>
                                                        <h3 className='clear_both'>{data.origin} - {data.destination}</h3>
                                                        <div className='content-block-cms'>
                                                        {fareRulesDetails ? (
                                                                <div dangerouslySetInnerHTML={{ __html: fareRulesDetails }} />
                                                            ) : (
                                                                <div>Fare Rules Are Not Available</div>
                                                            )}
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                }
                                {!this.state.vendorFareRule && !flightFareRulesData && this.props.flightFareRuleDetails.loading === false && this.props.kafilaFlightFareRuleDetails.loading === true && this.props.airIQFlightFareRuleDetails.loading === true  && this.props.goflyFlightFareRuleDetails.loading === true && this.props.eflyFlightFareRuleDetails.loading === true && this.props.satkarFlightFareRuleDetails.loading === true && this.props.fareBoutiqueFlightFareRuleDetails.loading === true && this.props.riyaFlightFareRuleDetails.loading === true && this.props.tripJackFlightFareRuleDetails.loading === false? <p><br />Fare Rules not available.<br /></p> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="popup-overlay"></div>
                </div>
                {/*Fare Detail Popup End*/}
            </div>
        )
    }
}

class RoundTripFlightSearchDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    toggleDetailsAccordion = (event, data) => {
        let flightType = document.getElementById(data.result_index).name;
        let selectedRadiobtn = document.getElementById(data.result_index);
        selectedRadiobtn.checked = true;
        if (flightType === 'returnFlightDetails') {
            this.props.getBottomReturnFlightsDetails([data]);
            this.props.getReturnFlightsDetails(data);
        }
        else {
            this.props.getBottomDepartureFlightsDetails([data]);
            this.props.getDepartureFlightsDetails(data);
        }

        this.props.setOpenSegment(data.result_index);
    }

    emailData = (event) => {
        this.props.saveEmailData(event);
    }

    fareData = (event, result_index, is_vendor, fare_rules, segments, api_source,uid,airline_remark,supplier_airline_remark,flightId,trackId) => {
        let airline_code = segments.length && segments[0].length && segments[0][0].airline && segments[0][0].airline.airline_code;
        this.props.showFareDetailsPopup(event, result_index, is_vendor, fare_rules, segments, api_source,uid,airline_remark,airline_code,supplier_airline_remark,flightId,trackId);
    }

    getSelectedFlightData = (event, data) => {
        if (event.target.name === 'returnFlightDetails') {
            this.props.getBottomReturnFlightsDetails([data]);
            this.props.getReturnFlightsDetails(data);
        }
        else {
            this.props.getBottomDepartureFlightsDetails([data]);
            this.props.getDepartureFlightsDetails(data);
        }
    }

    sameFlightAccordian = () => {
        const {collapseIndex, selectedCollapse} = this.props;
        
        let isOpenTarget   = (selectedCollapse !== collapseIndex && this.state.isOpen === true) ? false : !this.state.isOpen;
        this.setState({isOpen: isOpenTarget}, function()
        {
            if(this.state.isOpen === true)
                this.props.setSelectedCollapse(collapseIndex);
            else
                this.props.setSelectedCollapse(null);
        });
    }

    render() {
        let data = this.props.data;
        let { offeredFare } = this.props;
        let searchQuery = this.props.searchQuery;
        let segmentData = data.segments[0];
        let stopData = flightHelper.getFlightStops(segmentData);
        let selectedFlightIndex = this.props.selectedFlightIndex.result_index;

        function timeConvert(n) {
            var num = n;
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            if (rhours.toString().length < 2)
                rhours = "0" + rhours;
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if (rminutes.toString().length < 2)
                rminutes = "0" + rminutes;
            return rhours + ':' + rminutes;
        }
        function timeConversion(n) {
            var num = n;
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            if (rhours.toString().length < 2)
                rhours = "0" + rhours;
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if (rminutes.toString().length < 2)
                rminutes = "0" + rminutes;
            return rhours + 'h' + ':' + rminutes + 'm';
        }

        function diff_minutes(dt2, dt1) {
            var diff = (dt2.getTime() - dt1.getTime()) / 1000;
            diff /= 60;
            return Math.abs(Math.round(diff));
        }

        function diff_minutess(dt6, dt7, dt8, dt9, dt10) {
            var diffs = (dt7.getTime() - dt6.getTime()) / 1000;
            var diffss =
                (dt9.getTime() - dt8.getTime()) / 1000

            var diffsss = diffs + diffss
            diffsss /= 60;
            return Math.abs(Math.round(diffsss));
        }

        if (data.segments[0].length === 2) {
            var dt1 = new Date(data.segments[0][0].destination.arr_time);
            var dt2 = new Date(data.segments[0][1].origin.dep_time);
        }
        if (data.segments[0].length === 3) {

            var dt6 = new Date(data.segments[0][0].destination.arr_time)
            var dt7 = new Date(data.segments[0][1].origin.dep_time)
            var dt8 = new Date(data.segments[0][1].destination.arr_time)
            var dt9 = new Date(data.segments[0][2].origin.dep_time)
            var dt10 = dt9.getTime()
        }

        let noOfSeatAvailable = 0
        data && data.segments.map((item => {
             let result = item.reduce((prev, curr) => prev.no_of_seat_available < curr.no_of_seat_available ? prev : curr);
             noOfSeatAvailable = result.no_of_seat_available
        }))

        const {collapseIndex, selectedCollapse, sameFlightsLength, searchQueryDestionationOnward, dataDestinationOnward,searchQueryDestionationReturn, dataDestinationReturn, flightType, index, length} = this.props;
        return (
            (searchQuery.direct_flight === 'true' && (data.api_source === 'tripjack' || data.api_source === 'kafila' || data.api_source === 'tbo' || data.api_source === 'riya_travel') && (stopData[0] && stopData[0].stop_over && ((stopData[0].stop_over.length > 0 && stopData[0].stop_over[0] && stopData[0].stop_over[0].cityCode) || (stopData[0].stop_over === true)))) ?
            (index === 0 && length === 0 ? (
                <li className="no-result-round">
                    <figure><img src="assets/images/no-result.svg" alt="" /></figure>
                    <div className="no-result-text">
                        <h2>No results found</h2>
                        <p>We can’t find any item matching your search.</p>
                    </div>
                </li>
            ) : null) :
            <li className={this.props.openSegments.includes(data.result_index) === true ? "open" : ""} id='calchRoundHeight'>
                <div className="trip-card">
                    <div className="col-table-inner">
                        <div className="table-col table-col-first">
                            <div className="col-list-name">
                                <div className="radio-check yellow-check">
                                    <input type="radio"
                                        id={data.result_index}
                                        value="1"
                                        name={this.props.flightType}
                                        onChange={(event) => this.getSelectedFlightData(event, data)}
                                        checked = {selectedFlightIndex === data.result_index}
                                    />
                                    <label htmlFor={data.result_index}></label>
                                </div>
                                <div className="company-info"> <span className="mob-label">Airline</span>
                                    <figure><img src={data.segments[0][0].airline.flight_logo} alt="" /></figure>
                                    <div className="company-nm">
                                        <span>{flightHelper.getCombinedAirlineName(data.segments[0])}</span>
                                        <small>{flightHelper.getCombinedAirlineCode(data.segments[0])}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-col">
                            <span className="mob-label">Depart</span>
                            <div className="col-detail">
                                {
                                    flightHelper.getDepartureCityTime(data)
                                }
                                {/* <span>{flightHelper.getDepartureTime(data.segments[0][0].stop_point_departure_time)}</span>  */}
                                <small>{flightHelper.getDepartureDate(data.segments[0][0].origin.dep_time, 'DD MMM')}</small>
                            </div>
                        </div>
                        <div className="table-col"> <span className="mob-label">Arrive</span>
                            <div className="col-detail">
                                {
                                    flightHelper.getArrivalCityTime(data)
                                }
                                {/* <span>{flightHelper.getArivalTime(data.segments[0])}</span>  */}
                                <small>{flightHelper.getArivalDate(data.segments[0])}</small>
                            </div>
                        </div>
                        <div className="table-col"> <span className="mob-label">Duration</span>
                            <div className="col-detail">
                                    {
                                        data.api_source != 'tripjack' && data.api_source !== 'riya_travel' &&<span> {flightHelper.getCommonDuration(data.segments[0], ':', data.api_source, data)} </span>
                                    }
                                    {
                                        data.segments[0].length === 1 && data.api_source === 'tripjack' &&
                                        <span>{timeConvert(data.segments[0][0].duration)}</span>
                                    }
                                    {
                                        data.segments[0].length === 2 && data.api_source === 'tripjack' &&
                                        <span>{timeConvert(data.segments[0][0].duration + data.segments[0][1].duration + diff_minutes(dt1, dt2,))}</span>
                                    }
                                    {
                                        data.segments[0].length === 3 && data.api_source === 'tripjack' &&
                                        <span>{timeConvert(data.segments[0][0].duration + data.segments[0][1].duration + data.segments[0][2].duration + diff_minutess(dt6, dt7, dt8, dt9,))}</span>
                                    }  
                                    {
                                        data.api_source === 'riya_travel' &&
                                        <span> {timeConvert(data.segments[0] && data.segments[0].length > 0 && data.segments[0][data.segments[0].length - 1].duration)}</span>
                                    }
                                {/* ----------------------- Duration Non-stop Tooltip - starts here ----------------------- */}
                                { /* eslint-disable-next-line */}
                                <a href="javascript:void(0);" className="non-stop-click">
                                {
                                        data.api_source !== 'tripjack' &&
                                        <small>{flightHelper.getJournyType(stopData)}</small>
                                    }
                                    {
                                        data.api_source === 'tripjack' &&
                                        <small>{flightHelper.getJournyTypeTripJack(stopData)}</small>
                                    }
                                    <div className="non-stop-tooltip">
                                        <ul>
                                            {
                                                stopData.map(function (segment, segmentIndex) {
                                                    return (
                                                        <li key={segmentIndex}>
                                                            <div className="col-stop">{segment.airline_code + '-' + segment.flight_number}</div>
                                                            <div className="col-stop">{segment.origin_airport_code + (segment.origin_dep_time ? " (" + timeFormatHhMm(segment.origin_dep_time) + ")" : '') }</div>
                                                            <div className="col-stop last-stop">
                                                                <i className="icon icon-arrow-right2"></i>
                                                                {segment.destination_airport_code + (segment.destination_arr_time ? " (" + timeFormatHhMm(segment.destination_arr_time) + ")" : '' )}
                                                                {segment.craft && segment.remark && segment.craft === "BUS" ? <samp className="red-text">*</samp> : ""}</div>
                                                                {
                                                                    (segment.techStop) &&
                                                                    <div className="techstopmsg">1 Technical Stop</div>
                                                                }
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </a>
                                <small className="left-seat">{(noOfSeatAvailable !== null && noOfSeatAvailable !== "") ? flightHelper.kafilaNumberOfSeat(noOfSeatAvailable) + ' seat(s) left' : ''}</small>
                                {
                                            searchQueryDestionationOnward !== dataDestinationOnward && flightType==="departureFlightDetails" &&
                                            <div style={{
                                                display: 'inline-block',
                                                padding: '2px 6px',
                                                backgroundColor: '#e3eaf2',
                                                color: '#0f6dbf',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                borderRadius: '3px',
                                                textAlign: 'center',
                                                whiteSpace: 'nowrap',
                                                marginTop: '5px',
                                                border: '1px solid #b3c7e6',
                                                position: 'relative',  /* Position relative for centering */
                                                left:"50%",  /* Move to the center */
                                                transform: "translateX(-50%) "
                                            }}>
                                                Nearby Airport
                                            </div>
                                        }
                                         {
                                            searchQueryDestionationReturn !== dataDestinationReturn && flightType==="returnFlightDetails" &&
                                            <div style={{
                                                display: 'inline-block',
                                                padding: '2px 6px',
                                                backgroundColor: '#e3eaf2',
                                                color: '#0f6dbf',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                borderRadius: '3px',
                                                textAlign: 'center',
                                                whiteSpace: 'nowrap',
                                                marginTop: '5px',
                                                border: '1px solid #b3c7e6',
                                                position: 'relative',  /* Position relative for centering */
                                                left:"50%",  /* Move to the center */
                                                transform: "translateX(-50%) "
                                            }}>
                                                Nearby Airport
                                            </div>
                                        }
                            </div>
                        </div>
                        <div className="table-col table-col-last"> <span className="mob-label">Price</span>
                            <div className="col-detail">
                                <span><i className="fa fa-inr" aria-hidden="true"></i> {currencyFormat(data.fare.published_fare)}</span>
                                <small className="offered-fare" style={{ display: (offeredFare === true) ? 'block' : 'none' }}><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.offered_fare)}</small>
                                <small className="fare-breakup">Fare Breakup
                                    <div className="amount-detail fare-tooltip">
                                        <ul>
                                            <li><span className="pull-left">Base Fare</span>  <strong className="pull-right"><i className="fa fa-inr"></i>{currencyFormat(data.fare.base_fare)}</strong></li>
                                            {
                                                data.fare.other_charges !== 0
                                                    ? <li><span className="pull-left">Other Charges</span>  <strong className="pull-right"><i className="fa fa-inr"></i>{currencyFormat(data.fare.other_charges)}</strong></li>
                                                    : ''
                                            }

                                            {
                                                data.fare.tax !== 0
                                                    ? <li><span className="pull-left">Tax </span><strong className="pull-right"><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.tax)}</strong></li>
                                                    : ''
                                            }

                                            {
                                                data.fare.yq_tax !== 0
                                                    ? <li><span className="pull-left">YQ Tax {(data.api_source === 'tbo'  || data.api_source === 'tripjack') && '(Included)'}</span><strong className="pull-right"><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.yq_tax)}</strong></li>
                                                    : ''
                                            }

                                            {
                                                data.fare.other_tax !== 0
                                                    ? <li><span className="pull-left">Other Tax</span><strong className="pull-right"><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.other_tax)}</strong></li>
                                                    : ''
                                            }

                                            {
                                                data.fare.service_charge !== 0
                                                    ? <li><span className="pull-left">Service Charge</span><strong className="pull-right"><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.service_charge)}</strong></li>
                                                    : ''
                                            }
                                            {
                                                (data.fare.cgst > 0 || data.fare.igst > 0 || data.fare.sgst > 0) &&
                                                        <li><span className="pull-left">GST</span><strong className="pull-right"><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(parseFloat(data.fare.cgst) + parseFloat(data.fare.igst) + parseFloat(data.fare.sgst))}</strong></li>
                                            }

                                            {/* {
                                                data.fare.discount+data.fare.agent_discount !== 0 
                                                ?<li><span className="pull-left">Discount</span><strong className="pull-right"><i className="fa fa-inr" aria-hidden="true"></i>{currencyFormat(data.fare.discount+data.fare.agent_discount)}</strong></li>
                                                :''
                                            } */}

                                            <li className="last-amount-total"><strong><small>Total:</small><i className="fa fa-inr"></i>{currencyFormat(data.fare.published_fare)}</strong></li>
                                        </ul>
                                    </div>
                                </small>
                            </div>
                        </div>
                        {
                            <div className="fl-sme">
                                <span>
                                {
                                    (data.is_remark_available === true || data.is_vendor === 1) && (data.airline_remark ? data.airline_remark : '')
                                }
                                </span>
                            </div>
                        }
                    </div>
                    <div className="block-title">
                        <h2 onClick={(event) => this.toggleDetailsAccordion(event, data)} className={this.props.flightType}>Flight Detail</h2>
                        <div className="title-label">
                            <ul className="list-icon">
                                <li className="cabin-list-round">
                                    { /* eslint-disable-next-line */}
                                    <span className="select-tooltip">
                                        <i className="fa icon-bag" aria-hidden="true"></i>
                                        <div className="select-operated-tooltip">
                                            <div className="inner-tooltip-card">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Sector</th>
                                                            <th>Cabin</th>
                                                            <th>Check-in</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.segments[0].map(function (segment, segmentIndex) {
                                                                return (
                                                                    <tr key={segmentIndex}>
                                                                        <td><span>{segment.origin.airport.airport_code}</span> - <span> {segment.destination.airport.airport_code}</span></td>
                                                                        <td>{segment.cabin_baggage ? segment.cabin_baggage : '-'}</td>
                                                                        <td>{segment.baggage ? segment.baggage : '-'}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </span>
                                </li>
                                {/* Fare Rule Icon  --------------------------------------------------------------------- Start here */}
                                <li title="Fare Rules">
                                    { /* eslint-disable-next-line */}
                                    <a href="/" onClick={(event) => {event.preventDefault();this.fareData(event, data.result_index, data.is_vendor, data.fare_rules, data.segments, data.api_source,data.uid,data.airline_remark,data.supplier_airline_remark,data.flightId,data.trackId)}}>
                                        <i className="fa icon-edit-right" aria-hidden="true"></i>
                                    </a>
                                </li>
                                {/* Is Refundable Icon  --------------------------------------------------------------------- Start here */}
                                {
                                    data.is_refundable
                                        ?
                                        <li title="Refundable">
                                            { /* eslint-disable-next-line */}
                                            <a href="javascript:void(0)">
                                                <i className="fa icon icon-reg" aria-hidden="true"></i>
                                            </a>
                                        </li>
                                        :
                                        ""
                                }
                            </ul>
                            <span className="mail-icon">
                                <input type="checkbox" value={data.result_index} name="sendMail" onChange={(event) => this.emailData(event)} />
                                <i className="icon icon-mail1"></i> <i className="icon icon-checked"></i>
                            </span>
                        </div>
                    </div>
                    <div className="block-accordion">
                        <div className="block-describe">
                            <div className="info-detail">
                                <div className="time-duration-section">
                                    <ul className="flight-duration">
                                        {
                                            data.segments[0].map(function (segment, segmentIndex) {
                                                return (
                                                    <li key={segmentIndex}>
                                                        {
                                                            segmentIndex !== 0
                                                                ? <div className="time-col"><span>Change plane at {segment.origin.airport.city_name}</span></div>
                                                                : ""
                                                        }
                                                        <div className="detail-bx">
                                                            <div className="fl-info">

                                                                <div className="fl-name">
                                                                    <span>{flightHelper.getAirlineNameAndCode(segment)}</span>
                                                                    {
                                                                        (data.api_source === 'tripjack' || data.api_source === 'tbo' || data.api_source === 'riya_travel') &&
                                                                        <small>{flightHelper.getAirlineOperatingCarrier(segment)}</small>
                                                                    }
                                                                    {
                                                                        data.api_source !== 'omairiq' && data.api_source !== 'gofly'&& data.api_source !== 'efly' && data.api_source !== 'satkar' && data.api_source !== 'fbq' &&
                                                                        <small>{segment.craft && `Aircraft - ${segment.craft}`}</small>
                                                                    }
                                                                </div>


                                                                {/* Layover Start */}
                                                                <div className="fl-information">
                                                                    {
                                                                        segmentIndex !== 0 && timeDifference(data.segments[0][segmentIndex - 1].destination.arr_time, segment.origin.dep_time) > 0
                                                                            ? <span> Layover {minutesToDuration(timeDifference(data.segments[0][segmentIndex - 1].destination.arr_time, segment.origin.dep_time), 'h')}</span>
                                                                            : ""
                                                                    }
                                                                    {segment.craft && segment.remark && segment.craft === "BUS" ? <div className="fn-segment"><span className="red-text">{segment.remark}</span></div> : ""}
                                                                </div>
                                                                {/* Layover End */}


                                                            </div>
                                                            <div className="fl-detail">
                                                                <div className="fl-inner">
                                                                    <div className="fl-col"> <span> {segment.origin.airport.city_name} </span> <small> {dateFormat(segment.origin.dep_time, "DAY, DD MMM, HH:MM")} </small>
                                                                        <div className="terminal-nm"> <span> {segment.origin.airport.airport_name}{showValue(" - T-", segment.origin.airport.terminal, "")}</span> </div>
                                                                    </div>
                                                                    <div className="fl-mid">
                                                                        <div className="fl-mid-inner">
                                                                            <ul>
                                                                                <li className="time-info">
                                                                                {
                                                                                    data.api_source === 'riya_travel' &&
                                                                                    <span data-tooltip={timeConversion(segment.flying_time ? segment.flying_time : segment.duration)}><i className="icon icon-clock" ></i></span>
                                                                                }
                                                                                {
                                                                                    data.api_source !== 'riya_travel' &&
                                                                                    <span data-tooltip={flightHelper.getDuration(segment, 'h')}><i className="icon icon-clock" ></i></span>
                                                                                }
                                                                                </li>
                                                                                {
                                                                                    segment.cabin_baggage !== null
                                                                                        ? (<li className="mid-meal"> <span data-tooltip={segment.cabin_baggage}><i className="material-icons">card_travel</i></span></li>)
                                                                                        : ""
                                                                                }
                                                                                {
                                                                                    segment.baggage !== null
                                                                                        ? (<li className="seat-info"><span data-tooltip={segment.baggage}> <i className="material-icons">shopping_cart</i></span></li>)
                                                                                        : ""
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        {
                                                                            data.api_source === 'tripjack' && //segment.length === 1 &&
                                                                            <div className="time-col" style={{
                                                                                display: (
                                                                                    (segment && segment.stop_over && segment.stop_over.length >= 1) ? 'show' : 'none')

                                                                            }}>
                                                                                <span>Technical Stop Via {flightHelper.getCityCodeTripJack(segment)}</span>
                                                                            </div>
                                                                        }

                                                                        {
                                                                            (data.api_source === 'tbo' || data.api_source === 'kafila' || data.api_source === 'riya_travel') && //segment.length === 1 &&
                                                                            <div className="time-col" style={{
                                                                                display: (
                                                                                    (segment && segment.stop_over) ? 'show' : 'none')

                                                                            }}>
                                                                                <span>Technical Stop Via {segment && segment.stop_point_full_name}</span>
                                                                            </div>
                                                                        }
                                                                        <div className="mid-line flight-cabin"><span className="flight-icon"><img src="assets/images/flight-white.svg" alt="" /></span> </div>
                                                                        {
                                                                            segment.cabin_class_name !== null &&
                                                                            <div className='cabin-info' style={{ marginTop: '0px' }}>
                                                                                <strong style={{ color: '#fff', fontSize: '11px' }}>Cabin : </strong>
                                                                                <span style={{ color: 'rgb(255, 255, 255)', fontSize: '11px' }}>{segment.cabin_class_name}</span>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className="fl-col"> <span> {segment.destination.airport.city_name} </span> <small> {dateFormat(segment.destination.arr_time, "DAY, DD MMM, HH:MM")} </small>
                                                                        <div className="terminal-nm"> <span> {segment.destination.airport.airport_name} {showValue(" - T-", segment.destination.airport.terminal, "")}</span> </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        collapseIndex && 
                        <div className="same-flight-widget">
                             {/* eslint-disable-next-line */}
                            <a href="javascript:void(0);" onClick={() => this.sameFlightAccordian()} className={this.state.isOpen && selectedCollapse === collapseIndex ? "same-flight-btn active" : "same-flight-btn"}><span>+{sameFlightsLength} More Fares</span><i className="icon icon icon-arrow-down"></i></a>
                        </div>
                    }
                </div>
            </li>
        )
    }
}

export default FlightSearchDetailsRightRoundTripComponent