import React, { Component } from 'react';
import AgentChangePassword from '../../components/agent/AgentChangePasswordComponent';
import { connect } from 'react-redux'
import { myAccountAction, submitAgentChangePassword } from '../../actions/agentAction';
import { Formik } from "formik";
import * as Yup from "yup"
import { logoutAction } from '../../actions/shared/sessionAction';
import { settingAction } from '../../actions/shared/sharedAction';

class AgentPasswordChange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            agentChangePass:null,
        };
    }

    submitForm(values) {
        const formValues = {
            "old_password": values.oldPassword,
            "new_password": values.newPassword
            };
            const successCb = () => {
                this.setState({agentChangePass: 'success'});
            }   
            /* on error, update the error in the state */
            const errorCb = () => {
                this.setState({agentChangePass: 'error'});
        }
        this.props.submitAgentChangePassword(formValues,successCb,errorCb);
    } 

    render() {
        const initialValues = {
            old_password     : "",
            new_password     : ""
        };

        const validationSchema = Yup.object().shape({
            oldPassword: Yup.string()
                .required('Old password is required'),
            newPassword: Yup.string()
                .min(4, 'Password has to be longer than 4 characters') 
                .matches(/[a-z]/,'Password should have at least one lowercase character')
                .matches(/[A-Z]/,'Password should have at least one uppercase character')
                .matches(/[a-zA-Z]+[^a-zA-Z\s]+/, 'Password should have at least 1 number or special char.')
                .notOneOf([Yup.ref('oldPassword'),null],"Both old and new password can't same.")
                .required('New password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
                .required('Confirm password is required')
          });

        return (
            <Formik
                initialValues={initialValues} //Set initial values of fields to blank
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    this.submitForm(values);
                }}
                render={formikProps =>
                    <AgentChangePassword {...formikProps} {...this.props} {...this.state} />
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,
        productResponse : state.shared.productResponse,
        pagesResponse   : state.shared.pagesResponse,
        agentProductAccess     : state.shared.agentProductAccess,
        settingResponse: state.shared.settingResponse,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        myAccountAction: ()=> dispatch(myAccountAction()),
        submitAgentChangePassword           : (data,successCb,errorCb) => dispatch(submitAgentChangePassword(data,successCb,errorCb)),
        logoutAction: () => dispatch(logoutAction()),
        settingAction: (credentials) => dispatch(settingAction(credentials)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentPasswordChange)
