import React, { Component } from 'react';
import MyAccountComponent from '../../components/agent/MyAccountComponent';
import { connect } from 'react-redux'
import { myAccountAction,getBankDetail,updateAgentInfo } from '../../actions/agentAction';
import { logoutAction } from '../../actions/shared/sessionAction';
import { fileUpload } from '../../actions/shared/registerAction';
import { Formik } from "formik";
import * as Yup from "yup"
import { settingAction } from '../../actions/shared/sharedAction';

class MyAccount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            uploadFileData : null,
            updateAgentMsg: null,
        };
    }
    componentDidMount() {
        //this.props.myAccountAction();
        this.props.getBankDetail();
        this.props.settingAction();
    }

    //After all validations submit form
    submitForm(values) {
        const formValues = {
            "company_logo": values.company_logo
        };
        const successCb = () => {
            this.setState({updateAgentMsg: 'success'});
            this.props.myAccountAction();
        }   
        /* on error, update the error in the state */
        const errorCb = () => {
            this.setState({updateAgentMsg: 'error'});
        }
        this.props.updateAgentInfo(formValues,successCb,errorCb);
        
    } 

    //Function to be called from dumb view to upload file
    uploadfile=(self,file_type,file)=>{
        /* on successful file upload */
        const successCb = (success) => {
            self.props.setFieldValue(file_type, success);
        }
        /* on error, update the error in the state */
        const errorCb = () => {
            self.props.setFieldValue(file_type, '');
        }
        this.props.fileUpload(file,successCb,errorCb);  
    }

    render() {
        const SUPPORTED_FORMATS = [
            "image/jpg",
            "image/jpeg",
            "image/gif",
            "image/png",
            "image/svg"
        ];
        const initialValues = {
            company_logo            : "",
            company_logo_file       : ""
        };
        const validationSchemaBasicDetails = Yup.object().shape({
            company_logo_file:
                Yup.mixed()
                    .required("A file is required")
                    .test(
                        "fileFormat",
                        "Unsupported Format. Please select image",
                        value => value && SUPPORTED_FORMATS.includes(value.type)
                    )

        });
        return (
            <Formik
                initialValues={initialValues} //Set initial values of fields to blank
                validationSchema={validationSchemaBasicDetails}
                onSubmit={(values) => {
                    this.submitForm(values);
                }}
                render={formikProps =>
                    <MyAccountComponent {...formikProps} {...this.props} {...this.state}  uploadFile={this.uploadfile} />
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,//my account details
        productResponse : state.shared.productResponse,//get products for navigations
        agentBankDetails : state.myAccount.agentBankDetail,//get agent bank details
        fileUploadData      : state.register.uploadedFile, //for uploading any file
        agentProductAccess  : state.shared.agentProductAccess,
        settingResponse: state.shared.settingResponse,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        myAccountAction: (credentials) => dispatch(myAccountAction(credentials)),
        getBankDetail : () => dispatch(getBankDetail()),
        fileUpload  : (data,success,error) => dispatch(fileUpload(data,success,error)), //for uploading any file
        updateAgentInfo  : (data,success,error) => dispatch(updateAgentInfo(data,success,error)), //for updating agent info
        logoutAction: () => dispatch(logoutAction()),
        settingAction: (credentials) => dispatch(settingAction(credentials)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount)
