import React, { Component } from 'react';
import MyBookings from '../../components/agent/bookings/MyBookingsComponent';
import { connect } from 'react-redux';
import { getCalendarDetail, getUserType, getTransactionType, getBookingStatus, getMyBookings, cancelBooking, releasePnr, getMyHotelBookings, cancelHotelBooking, partialCancellationDetail, cancelTrainBooking, releaseTripJack, cancelTripjackBooking,cancelKafilaBooking, cancelTripjackHotelBooking,releaseHotelTripJack, myAccountAction, cancelRiyaBooking } from '../../actions/agentAction';
import { getCitiesAction, getHotelCitiesAction, settingAction  } from '../../actions/shared/sharedAction';
import { getAllAirlines } from '../../actions/flight/flightSearchAction';
import { Formik } from 'formik';
import { logoutAction } from '../../actions/shared/sessionAction';
import { getCurrentDate } from '../../shared/commonHelper';
import moment from 'moment';
import { getTrainBookings } from '../../actions/railway/railwayAction';
import {hotelTripjackCheckStatusDetails} from '../../actions/hotel/hotelBookAction'
import {kafilaPnrRetrieve,kafilaPnrCheck,tripJackBookDetails,kafilaCancelReasonSubmit,riyaBookDetails} from '../../actions/flight/flightBookTicketAction';

class AgentMyBookings extends Component {
    constructor(props) {
        super(props);
        let current_date = moment(getCurrentDate()).format('YYYY-MM-DD');
        this.state = {
            loading: true,
            error: null,
            //queryString : '?offset=0&limit=4'
            perPageLimit: 4,
            currentPage:1,
            limitOffSet : '',
            searchValues:'&booking_status=all&start_date='+current_date+'&end_date='+current_date,
            currentDate :current_date
        };
    }

    componentDidMount() {
        this.props.getCalendarDetail();
        this.props.getAllAirlines(null);
        this.props.getUserType();
        this.props.getTransactionType();
        this.props.settingAction();
        // this.props.getBookingStatus();
        var limitOffset = "?limit="+this.state.perPageLimit+"&offset=0&booking_status=all&start_date="+this.state.currentDate+"&end_date="+this.state.currentDate;
        this.setState({
            limitOffSet : limitOffset
        });
        this.props.getMyBookings(limitOffset);
    }

    getBookingStatus = (productCode) => {
        // const queryString = '?product='+productCode;
        this.props.getBookingStatus('?product='+productCode);
    }

    searching = (values,activePage=0) => {
        var offset;
        if(activePage === 0)
        {
            offset = 0*this.state.perPageLimit;
        }
        else
        {
            offset = (activePage-1)*this.state.perPageLimit;
        }


        var limitOffset = "?offset="+offset+"&limit="+this.state.perPageLimit;
        var searchValues = '&booking_status=all'//&start_date='+this.state.currentDate+"&end_date="+this.state.currentDate;
       // var searchValues = '&booking_status=all&start_date='+this.state.currentDate+"&end_date="+this.state.currentDate;
        //If transaction type search is filled then value else all
        limitOffset = values.transaction_type !== '' ? limitOffset+'&transaction_type='+values.transaction_type : limitOffset+'&transaction_type=all';
        //If booking status search is filled then value else all
        limitOffset = values.booking_status !== '' ? limitOffset+'&booking_status='+values.booking_status : limitOffset+'&booking_status=all';
        //If booking type search is filled then value else all
        limitOffset = values.booking_type !== '' ? limitOffset+'&booking_type='+values.booking_type : limitOffset+'&booking_type=all';
        //If airlines search is filled then value else all
        limitOffset = values.airlines !== '' ? limitOffset+'&airline='+values.airlines : limitOffset+'&airline=all';

        if(values.pnr_no !== ''){
            limitOffset = limitOffset+'&pnr_no='+values.pnr_no;
            searchValues = '&pnr_no='+values.pnr_no;
        }
        if(values.ticket_no !== ''){
            limitOffset = limitOffset+'&ticket_no='+values.ticket_no;
            searchValues = '&ticket_no='+values.ticket_no;
        }
        if(values.booking_transaction_ref !== ''){
            limitOffset = limitOffset+'&booking_transaction_ref='+values.booking_transaction_ref;
            searchValues = '&booking_transaction_ref='+values.booking_transaction_ref;
        }
        if(values.passenger_name !== ''){
            limitOffset = limitOffset+'&passenger_name='+values.passenger_name;
            searchValues = '&passenger_name='+values.passenger_name;
        }
        if(values.originCityName && values.originCityName !== ''){
            limitOffset = limitOffset+'&origin='+values.originCityName;
            searchValues = '&origin='+values.originCityName;
        }
        if(values.destinationCityName && values.destinationCityName !== ''){
            limitOffset = limitOffset+'&destination='+values.destinationCityName;
            searchValues = '&destination='+values.destinationCityName;
        }
      
        if(values.invoice_no !== ''){
            limitOffset = limitOffset+'&invoice_no='+values.invoice_no;
            searchValues = '&invoice_no='+values.invoice_no;
        }

        if(values.start_date && values.start_date !== ''){
         //   limitOffset = limitOffset+'&start_date='+values.start_date;
            searchValues = '&start_date='+values.start_date;
        }
        if(values.end_date && values.end_date !== ''){
          //  limitOffset = limitOffset+'&end_date='+values.end_date;
            searchValues = '&end_date='+values.end_date;
        }

        if(values.ticket_no === '' && values.pnr_no === '' && values.booking_transaction_ref === '' && values.invoice_no === ''){
            limitOffset = limitOffset+'&start_date='+values.start_date+ '&end_date='+values.end_date;
           // searchValues = searchValues + '&start_date='+this.state.currentDate+"&end_date="+this.state.currentDate;
        }

        this.setState({
            limitOffSet : limitOffset,
            searchValues : searchValues
        });
        this.props.getMyBookings(limitOffset);
    }

    getRecordsPerPage(activePage,values) {
        this.setState({currentPage: activePage});
        this.searching(values,activePage);
        // this.setState({currentPage: activePage});
        // var offset = (activePage-1)*this.state.perPageLimit;
        // var limitOffset = "?offset="+offset+"&limit="+this.state.perPageLimit;
        // this.setState({
        //     limitOffSet : limitOffset
        // });
        // this.props.getMyBookings(limitOffset+''+this.state.searchValues);
        // this.setState({
        //     searchValues : '&booking_status=ticket_confirmed'
        // });     
    }

    render() {
        const initialValues = {
            user_type : '',
            transaction_type : '',
            booking_status : 'all',
            pnr_no : '',
            ticket_no : '',
            booking_transaction_ref : '',
            passenger_name : '',
            start_date : this.state.currentDate,
            end_date : this.state.currentDate,
            origin :'',
            destination :'',
            airlines :'',
            booking_type : '',
            invoice_no : ''
        };

        return (
            <div>
                <Formik
                    initialValues={initialValues} //Set initial values of fields to blank
                    onSubmit={(values) => {
                        this.searching(values);
                    }}
                    render={formikProps =>
                        <MyBookings {...this.props} {...this.state} {...formikProps} getRecordsPerPage={this.getRecordsPerPage.bind(this)} getBookingStatus={this.getBookingStatus.bind(this)}/>
                    }
                />

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,
        productResponse: state.shared.productResponse,
        pagesResponse: state.shared.pagesResponse,
        calendarResponse: state.myAccount.calendarResponse,
        citiesResponse: state.shared.citiesResponse,
        allAirlinesData: state.flightSearchReducer.allAirlinesData,
        userTypeDetails: state.myAccount.userTypeDetails,
        transactionTypeDetails: state.myAccount.transactionTypeDetails,
        bookingStatusDetails: state.myAccount.bookingStatusDetails,
        myAccountDetails: state.myAccount.myAccountDetails,
        myHotelBookingDetails: state.myAccount.myHotelBookingDetails,
        cancelBookingDetail: state.myAccount.cancelBookingDetail,
        cancelHotelBookingDetail: state.myAccount.cancelHotelBookingDetail,
        cancelTrainBookingDetail: state.myAccount.cancelTrainBookingDetail,
        settingResponse: state.shared.settingResponse,
        releasePnrDetail: state.myAccount.releasePnrDetail,
        agentProductAccess: state.shared.agentProductAccess,
        hotelCitiesResponse: state.shared.hotelCitiesResponse,
        partailCancellationInfo: state.myAccount.partailCancellationInfo,
        myTrainBookings: state.railwayReducer.myTrainBookings,
        pnrRetrieveData: state.flightBookTicketReducer.pnrRetrieveData,//get response from pnr retrieve
        pnrCheckData: state.flightBookTicketReducer.pnrCheckData,
        locations: state.register.locations,
        releaseTripJackDetail: state.myAccount.releaseTripJackDetail,
        tripJackTicketBookDetails : state.flightBookTicketReducer.tripJackTicketBookDetails, // get response form trip Jack Book Details api
        ticketBookingData: state.flightBookTicketReducer.ticketBookingData,//get response from ticket booking,
        cancelTripJackBookingDetail: state.myAccount.cancelTripJackBookingDetail, // tripjack cancel booking 
        get_kafila_pnr_cancel_reason:state.flightBookTicketReducer.kafilaPnrCancelReason,
        kafila_cancel_booking_details:state.myAccount.kafila_cancel_pnr, // kafila cancel booking
        statusCheckDataTripjackData:state.hotelBookReducer.statusCheckDataTripjack,
        releaseHotelTripJackDetail:state.myAccount.releaseHotelTripJackDetail,
        cancelRiyaBookingDetail: state.myAccount.cancelRiyaBookingDetail, // riya cancel booking 
        riyaBookDetails : state.flightBookTicketReducer.riyaBookDetails,
        riyaTicketBookDetails : state.flightBookTicketReducer.riyaTicketBookDetails,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getCalendarDetail: () => dispatch(getCalendarDetail()),
        getCitiesAction: (credentials, successCb, errorCb) => dispatch(getCitiesAction(credentials, successCb, errorCb)),
        getAllAirlines: (credentials) => dispatch(getAllAirlines(credentials)),
        getUserType: () => dispatch(getUserType()),
        getTransactionType: () => dispatch(getTransactionType()),
        getBookingStatus: (queryString) => dispatch(getBookingStatus(queryString)),
        logoutAction: () => dispatch(logoutAction()),
        getMyBookings: (queryString) => dispatch(getMyBookings(queryString)),
        getMyHotelBookings: (queryString) => dispatch(getMyHotelBookings(queryString)),
        cancelBooking: (data, successCb, errorCb) => dispatch(cancelBooking(data, successCb, errorCb)),
        cancelHotelBooking: (data, successCb, errorCb) => dispatch(cancelHotelBooking(data, successCb, errorCb)),
        cancelTrainBooking: (data, successCb, errorCb) => dispatch(cancelTrainBooking(data, successCb, errorCb)),
        releasePnr: (data, successCb, errorCb) => dispatch(releasePnr(data, successCb, errorCb)),
        getHotelCitiesAction: (credentials, successCb, errorCb) => dispatch(getHotelCitiesAction(credentials, successCb, errorCb)),
        partialCancellationDetail: (credentials) => dispatch(partialCancellationDetail(credentials)),
        getTrainBookings: (data, queryString, successCb, errorCb) => dispatch(getTrainBookings(data, queryString, successCb, errorCb)),
        kafilaPnrRetrieve: (data, successCb, errorcb) => dispatch(kafilaPnrRetrieve(data, successCb, errorcb)),
        kafilaPnrCheck:(data,successCb, errorCb) => dispatch(kafilaPnrCheck(data, successCb, errorCb)),
        releaseTripJack: (data, successCb, errorCb) => dispatch(releaseTripJack(data, successCb, errorCb)),
        tripJackBookDetails : (credentials,successCb,errorcb) => dispatch(tripJackBookDetails(credentials, successCb, errorcb)), //Tripjack book details
        cancelTripjackBooking: (data, successCb, errorCb) => dispatch(cancelTripjackBooking(data, successCb, errorCb)),
        cancelKafilaBooking: (data, successCb, errorCb) => dispatch(cancelKafilaBooking(data, successCb, errorCb)),
        cancelTripjackHotelBooking: (data, successCb, errorCb) => dispatch(cancelTripjackHotelBooking(data, successCb, errorCb)),
        kafilaCancelReasonSubmit:(data,)=>dispatch(kafilaCancelReasonSubmit(data )),
        hotelTripjackCheckStatusDetails:(data, successCb, errorCb) => dispatch(hotelTripjackCheckStatusDetails(data, successCb, errorCb)),
        releaseHotelTripJack: (data, successCb, errorCb) => dispatch(releaseHotelTripJack(data, successCb, errorCb)),
        myAccountAction: () => dispatch(myAccountAction()),
        cancelRiyaBooking: (data, successCb, errorCb) => dispatch(cancelRiyaBooking(data, successCb, errorCb)),
        riyaBookDetails: (credentials, successCb, errorcb) => dispatch(riyaBookDetails(credentials, successCb, errorcb)), //riya book details
        settingAction: (credentials) => dispatch(settingAction(credentials)),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentMyBookings)
