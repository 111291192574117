import React, { Component } from 'react';
import CreateReportComponent from '../../components/agent/CreateReportComponent';
import { connect } from 'react-redux'
import { logoutAction } from '../../actions/shared/sessionAction';
import { bookingReport, myAccountAction } from '../../actions/agentAction';
import { settingAction } from '../../actions/shared/sharedAction';

class CreateReportContainer extends Component {
    render() {
        return (
           <CreateReportComponent {...this.props}/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountResponse: state.myAccount,
        productResponse : state.shared.productResponse,
        pagesResponse   : state.shared.pagesResponse,
        agentProductAccess     : state.shared.agentProductAccess,
        bookingReportData      : state.myAccount.bookingReportData,
        settingResponse: state.shared.settingResponse,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        logoutAction    : () => dispatch(logoutAction()),
        bookingReport   : (credentials) => dispatch(bookingReport(credentials)),
        myAccountAction    : () => dispatch(myAccountAction()),
        settingAction: (credentials) => dispatch(settingAction(credentials)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReportContainer)
