import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { newRootUrl, oldRootUrl } from '../../shared/constant';

class DomainRedirect extends Component {
    componentDidMount() {
        this.handleRedirect();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.handleRedirect();
        }
    }

    handleRedirect = () => {
        const { location } = this.props;
        const currentHost = window.location.host;

        const protectedPaths = [
            '/my-account', '/change-password', '/my-bookings',
            '/view-flight-booking', '/view-train-booking', '/view-hotel-booking',
            '/print-ticket', '/print-invoice', '/booking', '/hotel-booking',
            '/request-bookings', '/request-flight-booking',
            '/mark-up-tool', '/flight-type-list', '/domestic-markup-tool', '/international-markup-tool', '/hotel-type-list',
            '/deposits', '/view-deposit', '/deposit-request',
            '/credits', '/view-credits', '/credit-request',
            '/bank-detail', '/account-statement', '/create-report', '/booking-calendar',
            '/top-up', '/view-topup', '/topup-request',
            '/view-visa', '/print-credit-note', '/topup-request/processing',
            '/print-hotel-invoice', '/print-receipt-voucher', '/hotel-voucher', '/print-hotel-credit-note',
            '/about-us', '/contact-us', '/terms-and-conditions',
            '/view-vendor-bookings','/add-vendor-booking','/vendor','/edit-vendor-booking','/pending-booking','/view-booking-detail'
        ];

        const targetUrl = protectedPaths.includes(location.pathname)
            ? `${oldRootUrl}${location.pathname}${location.search}`
            : `${newRootUrl}${location.pathname}${location.search}`;
        if (window.location.href !== targetUrl) {
            window.location.href = targetUrl;
        }
    };

    render() {
        return null;
    }
}

export default withRouter(DomainRedirect);
